export const GoogleMapConst = {
    ZOOM: {
        MIN: 1,
        DEFAULT: 14,
        LEVEL_14: 14,
        LEVEL_15: 15,
        LEVEL_16: 16,
        LEVEL_17: 17,
        LEVEL_18: 18,
        LEVEL_19: 19,
        LEVEL_20: 20,
        MAX: 21,
    },
    ZOOM_FRACTIONS: {
        LEVEL_14: 0.01,
        LEVEL_15: 0.004,
        LEVEL_16: 0.002,
        LEVEL_17: 0.001,
        LEVEL_18_AND_19: 0.0003,
        LEVEL_20: 0.00015,
        LEVEL_21: 0.00007
    },
};