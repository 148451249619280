export const relay42constans = {
    engagements: {
        dynamicPageview: "DynamicPageview",
        holidaySearch: "holidaySearch"
    },
    apiEvents: {
        result: "result",
        holiday: "holiday",
        holidayChangeSearch: "holidayChangeSearch",
        checkAvailability: "checkAvailability",
        changeFlight: "changeFlight",
        extras: "extras",
        checkout: "checkout",
        payment: "payment",
        confirmation: "confirmation"
    },
    apiPaths: {
        result: "/relay42/result/",
        holiday: "/relay42/holiday/",
        holidayChangeSearch: "/relay42/result/holidaychangesearch",
        checkAvailability: "/relay42/holiday/checkavailability/",
        changeFlight: "/relay42/holiday/changeFlight", 
        extras: "/relay42/extras/",
        checkout: "/relay42/checkout/",
        payment: "/relay42/payment/",
        confirmation: "/relay42/confirmation/"
    }
};
