import { useCallback, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { action } from "../../../common/constants/action";
import { filterType } from "../../../common/constants/filterType";
import { LocalStorageEnum } from "../../../common/enums/localStorageEnum";
import { searchParamsEnum } from "../../../common/enums/searchParamsEnum";
import config from "../../../environments/config.json";
import { LocalStorageService } from "../../../services/local-storage.service";
import { Accordion } from "../../accordion/accordion";
import "./destination-filter.component.scss";

export const DestinationFilterComponent = ({
  contentfulButtons,
  contentfulFilters,
  data,
  setDestinationFilter,
  defaultSearchUsed,
}) => {
  const [apiCountryFilterOptions, setStateApiCountryFilterOptions] =
    useState(null);
  const [apiCityFilterOptions, setStateApiCityFilterOptions] = useState(null);
  const [countryFilterOptions, setStateCountryFilterOptions] = useState(null);
  const [cityFilterOptions, setStateCityFilterOptions] = useState(null);
  const [
    selectedMaximumNumberOfCountries,
    setStateSelectedMaximumNumberOfCountries,
  ] = useState(false);
  const [saveButtonDisabled, setStateSaveButtonDisabled] = useState(false);
  const [
    showAvailableCountryFilterOptions,
    setStateShowAvailableCountryFilterOptions,
  ] = useState(false);
  const [
    showAvailableCityFilterOptions,
    setStateShowAvailableCityFilterOptions,
  ] = useState(false);
  const [countriesSelectorText, setStateCountriesSelectorText] = useState(null);
  const [citiesSelectorText, setStateCitiesSelectorText] = useState(null);
  const destinationFilterComponentSectionCountryFilterOptions = useRef(null);
  const destinationFilterComponentSectionCityFilterOptions = useRef(null);
  const backupApiCountryFilterOptions = useRef(null);
  const backupApiCityFilterOptions = useRef(null);
  const filtersButtonContainer = document.getElementById(
    "filters-button-container"
  );
  const destinationFilterEnum = {
    numberOfCountryNamesToDisplay: 2,
    numberOfCityNamesToDisplay: 2,
  };

  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setStateApiCountryFilterOptions(data?.countryFilterOptions);
    setStateApiCityFilterOptions(data?.cityFilterOptions);

    if (apiCountryFilterOptions) {
      setCountriesSelectorText(apiCountryFilterOptions);
      setStateCountryFilterOptions(
        JSON.parse(JSON.stringify(apiCountryFilterOptions))
      );
      backupApiCountryFilterOptions.current = JSON.parse(
        JSON.stringify(apiCountryFilterOptions)
      );
    }

    if (apiCityFilterOptions) {
      setCitiesSelectorText(apiCityFilterOptions);
      setStateCityFilterOptions(
        JSON.parse(JSON.stringify(apiCityFilterOptions))
      );
      backupApiCityFilterOptions.current = JSON.parse(
        JSON.stringify(apiCityFilterOptions)
      );
    }
  }, [data, apiCountryFilterOptions, apiCityFilterOptions]);

  const setCountriesSelectorText = (collection) => {
    const displayNames = collection
      .filter((element) => element.selected)
      .map((element) => element.displayName);

    if (
      displayNames.length <= destinationFilterEnum.numberOfCountryNamesToDisplay
    ) {
      const text = displayNames.join(", ");
      setStateCountriesSelectorText(text);
    } else {
      const text =
        displayNames
          .slice(0, destinationFilterEnum.numberOfCountryNamesToDisplay)
          .join(", ") +
        `, +${
          displayNames.length -
          destinationFilterEnum.numberOfCountryNamesToDisplay
        }`;
      setStateCountriesSelectorText(text);
    }
  };

  const setCitiesSelectorText = (collection) => {
    const displayNames = collection
      .filter((element) => element.selected)
      .map((element) => element.displayName);

    if (
      displayNames.length <= destinationFilterEnum.numberOfCityNamesToDisplay
    ) {
      const text = displayNames.join(", ");
      setStateCitiesSelectorText(text);
    } else {
      const text =
        displayNames
          .slice(0, destinationFilterEnum.numberOfCityNamesToDisplay)
          .join(", ") +
        `, +${
          displayNames.length - destinationFilterEnum.numberOfCityNamesToDisplay
        }`;
      setStateCitiesSelectorText(text);
    }
  };

  const addEventListenerCountries = useCallback(() => {
    document.addEventListener("click", handleClickEventCountries);
  });

  const removeEventListenerCountries = useCallback(() => {
    document.removeEventListener("click", handleClickEventCountries);
  });

  const handleClickEventCountries = useCallback((event) => {
    if (
      destinationFilterComponentSectionCountryFilterOptions.current &&
      !destinationFilterComponentSectionCountryFilterOptions.current.contains(
        event.target
      )
    ) {
      handleClickOutsideElement(filterType.countries);
    } else {
      setStateShowAvailableCountryFilterOptions(
        !showAvailableCountryFilterOptions
      );
    }
  }, []);

  const addEventListenerCities = useCallback(() => {
    document.addEventListener("click", handleClickEventCities);
  });

  const removeEventListenerCities = useCallback(() => {
    document.removeEventListener("click", handleClickEventCities);
  });

  const handleClickEventCities = useCallback((event) => {
    if (
      destinationFilterComponentSectionCityFilterOptions.current &&
      !destinationFilterComponentSectionCityFilterOptions.current.contains(
        event.target
      )
    ) {
      handleClickOutsideElement(filterType.cities);
    } else {
      setStateShowAvailableCityFilterOptions(!showAvailableCityFilterOptions);
    }
  }, []);

  const handleClickOutsideElement = (type) => {
    handleOnClickCancel(type);
  };

  const toggleFiltersButtonContainerVisibility = (setVisible) => {
    if (setVisible) {
      filtersButtonContainer?.classList.remove("hidden");
      filtersButtonContainer?.classList.add("visible");
    } else {
      filtersButtonContainer?.classList.remove("visible");
      filtersButtonContainer?.classList.add("hidden");
    }
  };

  const toggleShowAvailableFilterOptions = (type) => {
    toggleFiltersButtonContainerVisibility();

    if (type === filterType.countries) {
      if (!showAvailableCountryFilterOptions) {
        backupApiCountryFilterOptions.current =
          structuredClone(countryFilterOptions);
        setSelectedMaximumNumberOfCountries();
        setSaveButtonDisabled();
        setStateShowAvailableCountryFilterOptions(
          !showAvailableCountryFilterOptions
        );
        addEventListenerCountries();
      } else {
        setStateShowAvailableCountryFilterOptions(
          !showAvailableCountryFilterOptions
        );
        removeEventListenerCountries();
      }
    } else if (type === filterType.cities) {
      if (!showAvailableCityFilterOptions) {
        backupApiCityFilterOptions.current = structuredClone(cityFilterOptions);
        setStateShowAvailableCityFilterOptions(!showAvailableCityFilterOptions);
        addEventListenerCities();
      } else {
        setStateShowAvailableCityFilterOptions(!showAvailableCityFilterOptions);
        removeEventListenerCities();
      }
    }
  };

  const setSelectedMaximumNumberOfCountries = () => {
    setStateSelectedMaximumNumberOfCountries(
      countryFilterOptions &&
        countryFilterOptions.filter((element) => element.selected).length >=
        filterType.maximumNumberOfCountries
        ? true
        : false
    );
  };

  const setSaveButtonDisabled = () => {
    setStateSaveButtonDisabled(
      countryFilterOptions &&
        (countryFilterOptions.filter((element) => element.selected).length ===
          0 ||
          countryFilterOptions.filter((element) => element.selected).length >
            filterType.maximumNumberOfCountries)
        ? true
        : false
    );
  };

  const handleOnClickFilterOptions = (type, index) => {
    if (type === filterType.countries) {
      const updatedCountryFilterOptions = [...countryFilterOptions];
      updatedCountryFilterOptions[index].selected =
        !updatedCountryFilterOptions[index].selected;
      setStateCountryFilterOptions(updatedCountryFilterOptions);
      setSelectedMaximumNumberOfCountries();
      setSaveButtonDisabled();
      setCountriesSelectorText(updatedCountryFilterOptions);
    } else if (type === filterType.cities) {
      const updatedCityFilterOptions = [...cityFilterOptions];
      updatedCityFilterOptions[index].selected =
        !updatedCityFilterOptions[index].selected;
      setStateCityFilterOptions(updatedCityFilterOptions);
      setCitiesSelectorText(updatedCityFilterOptions);
    }
  };

  const handleOnClickCancel = (type) => {
    toggleFiltersButtonContainerVisibility(true);

    if (type === filterType.countries) {
      setStateShowAvailableCountryFilterOptions(false);
      setStateCountryFilterOptions(backupApiCountryFilterOptions.current);
      setCountriesSelectorText(backupApiCountryFilterOptions.current);
      removeEventListenerCountries();
    } else if (type === filterType.cities) {
      setStateShowAvailableCityFilterOptions(false);
      setStateCityFilterOptions(backupApiCityFilterOptions.current);
      setCitiesSelectorText(backupApiCityFilterOptions.current);
      removeEventListenerCities();
    }
  };

  const handleOnClickSave = (type) => {
    toggleFiltersButtonContainerVisibility(true);

    if (!saveButtonDisabled) {
      const output = [...countryFilterOptions, ...cityFilterOptions]
        .filter((element) => element.selected)
        .map((element) => {
          return {
            type: filterType.destination,
            value: element.destinationCode,
            text: element.displayName,
            action: action.add,
          };
        });
      const destinationParams = output
        .map((selectedFilter) => selectedFilter.value)
        .join(",");

      setSearchParams((params) => {
        params.set(searchParamsEnum.destinationLocation, destinationParams);
        return params;
      });
      LocalStorageService.setLocalStorageItem(
        LocalStorageEnum.DESTINATION_LOCATIONS,
        destinationParams
      );

      setDestinationFilter(output);
      setStateShowAvailableCountryFilterOptions(false);
      setStateShowAvailableCityFilterOptions(false);
      if (type === filterType.countries) {
        removeEventListenerCountries();
      } else if (type === filterType.cities) {
        removeEventListenerCities();
      }
    }
  };

  const sectionCountryFilterOptions = (
    <div
      id="destination-filter-component-section-country-filter-options"
      className="filter-options"
      ref={destinationFilterComponentSectionCountryFilterOptions}
    >
      <div className="header">
        {contentfulFilters
          ? contentfulFilters[0]?.fields?.destinationFilterCountriesHeader
          : "Countries"}
      </div>

      <div
        className={
          showAvailableCountryFilterOptions ? "selector active" : "selector"
        }
        onClick={() => {
          toggleShowAvailableFilterOptions(filterType.countries);
        }}
      >
        <div className="icon-location">&nbsp;</div>
        <div className="text">
          {countriesSelectorText ? (
            <div className="active">{countriesSelectorText}</div>
          ) : (
            <div className="inactive">
              {contentfulFilters
                ? contentfulFilters[0]?.fields
                    ?.destinationFilterCountriesSelectorPrompt
                : "Choose a country..."}
            </div>
          )}
        </div>
        <div className="icon-arrow">
          <div
            className={
              showAvailableCountryFilterOptions
                ? "icon-drop-up"
                : "icon-drop-down"
            }
          ></div>
        </div>
      </div>

      <div className="options-container">
        {showAvailableCountryFilterOptions ? (
          <>
            <div className="options-content-container">
              <div className="options-header">
                {contentfulFilters
                  ? contentfulFilters[0]?.fields
                      ?.destinationFilterCountriesOptionsHeader
                  : "Select up to 3 countries"}
              </div>

              {selectedMaximumNumberOfCountries ? (
                <div className="options-error">
                  <div className="icon"></div>
                  <div className="text">
                    {contentfulFilters
                      ? contentfulFilters[0]?.fields
                          ?.destinationFilterCountriesOptionsErrorText
                      : "You have selected the maximum number of 3 countries."}
                  </div>
                </div>
              ) : null}

              <div className="options-content">
                {countryFilterOptions?.map((element, index) => {
                  return (
                    <div
                      className={`option 
                                 ${(selectedMaximumNumberOfCountries  && countryFilterOptions[index]?.selected) || !selectedMaximumNumberOfCountries ? '' : 'disable-option-cursor'} `}
                      key={index}
                      onClick={
                        (selectedMaximumNumberOfCountries  && countryFilterOptions[index]?.selected) || !selectedMaximumNumberOfCountries ? 
                         () => {handleOnClickFilterOptions(filterType.countries, index)} : undefined
                        }
                    >
                      <div
                        className={`checkbox-container 
                          ${countryFilterOptions && countryFilterOptions[index]?.selected ? "active": "inactive"} 
                          ${selectedMaximumNumberOfCountries && !countryFilterOptions[index]?.selected? 'disable-checkbox': ''}`
                        }
                      >
                        <div className={`checkbox 
                                        ${(selectedMaximumNumberOfCountries && !countryFilterOptions[index]?.selected)  ? 'disable-checkbox' : ''}`}
                        >
                        </div>
                      </div>

                      <div className="flag-container">
                        <img
                          className={`flag-small 
                                     ${(selectedMaximumNumberOfCountries && !countryFilterOptions[index]?.selected)  ? 'disable-flag' : ''}`}
                          alt={element.countryCode}
                          src={`${
                            config.COUNTRY_FLAGS
                          }/${element.countryCode.toLowerCase()}.png`}
                        />
                      </div>
              
                      <div className={`text-container
                                    ${(selectedMaximumNumberOfCountries && !countryFilterOptions[index]?.selected)  ? 'disable-text' : ''}`}
                      >
                        {element.displayName}
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="options-button-container">
                <div
                  onClick={() => {
                    handleOnClickCancel(filterType.countries);
                  }}
                >
                  {contentfulButtons
                    ? contentfulButtons[0]?.fields?.cancel
                    : "Cancel"}
                </div>
                <div
                  className={saveButtonDisabled ? "disabled" : "enabled"}
                  onClick={() => {
                    handleOnClickSave(filterType.countries);
                  }}
                >
                  {contentfulButtons
                    ? contentfulButtons[0]?.fields?.save
                    : "Save"}
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );

  const sectionCityFilterOptions = (
    <div
      id="destination-filter-component-section-city-filter-options"
      className="filter-options"
      ref={destinationFilterComponentSectionCityFilterOptions}
    >
      <div className="header">
        {contentfulFilters
          ? contentfulFilters[0]?.fields?.destinationFilterCitiesHeader
          : "Cities"}
      </div>

      <div
        className={
          showAvailableCityFilterOptions ? "selector active" : "selector"
        }
        onClick={() => {
          toggleShowAvailableFilterOptions(filterType.cities);
        }}
      >
        <div className="icon-location">&nbsp;</div>
        <div className="text">
          {citiesSelectorText ? (
            <div className="active">{citiesSelectorText}</div>
          ) : (
            <div className="inactive">
              {contentfulFilters
                ? contentfulFilters[0]?.fields
                    ?.destinationFilterCitiesSelectorPrompt
                : "Choose a city..."}
            </div>
          )}
        </div>
        <div className="icon-arrow">
          <div
            className={
              showAvailableCityFilterOptions ? "icon-drop-up" : "icon-drop-down"
            }
          ></div>
        </div>
      </div>

      <div className="options-container">
        {showAvailableCityFilterOptions ? (
          <>
            <div className="options-content-container">
              <div className="options-header">
                {contentfulFilters
                  ? contentfulFilters[0]?.fields
                      ?.destinationFilterCitiesOptionsHeader
                  : "Select a city"}
              </div>

              <div className="options-content">
                {cityFilterOptions?.map((element, index) => {
                  return (
                    <div
                      key={index}
                      className="option"
                      onClick={() => {
                        handleOnClickFilterOptions(filterType.cities, index);
                      }}
                    >
                      <div
                        className={
                          cityFilterOptions &&
                          cityFilterOptions[index]?.selected
                            ? "checkbox-container active"
                            : "checkbox-container inactive"
                        }
                      >
                        <div className="checkbox"></div>
                      </div>

                      <div className="flag-container">
                        <img
                          className="flag-small"
                          alt={element.countryCode}
                          src={`${
                            config.COUNTRY_FLAGS
                          }/${element.countryCode.toLowerCase()}.png`}
                        />
                      </div>

                      <div className="text-container">
                        {element.displayName}
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="options-button-container">
                <div
                  onClick={() => {
                    handleOnClickCancel(filterType.cities);
                  }}
                >
                  {contentfulButtons
                    ? contentfulButtons[0]?.fields?.cancel
                    : "Cancel"}
                </div>
                <div
                  className="enabled"
                  onClick={() => {
                    handleOnClickSave(filterType.cities);
                  }}
                >
                  {contentfulButtons
                    ? contentfulButtons[0]?.fields?.save
                    : "Save"}
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );

  return (
    <div
      id="destination-filter-component-container"
      className="destination-filter-component-container"
    >
      <Accordion
        startingExpandedState="false"
        defaultSearchUsed={defaultSearchUsed}
        labelText={
          contentfulFilters
            ? contentfulFilters[0]?.fields?.destinationFilterHeader
            : "Destination"
        }
      >
        <div className="destination-filter-component-content">
          {sectionCountryFilterOptions}
          {sectionCityFilterOptions}
        </div>
      </Accordion>
    </div>
  );
};
