import axios from "axios";
import { affiliates } from "../../common/enums/affiliates";
import { relay42constans } from "../../common/constants/relay42";
import { GetApiUrl } from "../general.service";
import { httpRequestMethods } from "../../common/enums/httpRequestMethods";

let affiliate = { name: null };
let currentPathname = "";
const relayScript = {
  eventName: relay42constans.engagements.dynamicPageview,
  eventData: {},
};

const initRelay42 = () => {
  if (process.env.REACT_APP_AFFILIATE === affiliates.klm) {
    affiliate.name = affiliates.klm;
    initRelay42forKLM();
  } else if (process.env.REACT_APP_AFFILIATE === affiliates.transavia) {
    affiliate.name = affiliates.transavia;
    initRelay42forTransavia();
  }
};

const addRelay42StyleSheetHV = () => {
  const style_css = document.createElement("link");
  style_css.rel = "stylesheet";
  style_css.type = "text/css";
  style_css.id = "relay42StyleSheetForHV";
  document.getElementsByTagName("head")[0].appendChild(style_css);
};

const initRelay42forKLM = () => {
  if (!document.getElementById(`relay42-${affiliate.name}`)) {
    window.dataLayer = window.dataLayer || [];
    const script_node = document.createElement("script");
    script_node.setAttribute("id", `relay42-${affiliate.name}`);
    script_node.innerHTML = `(function(a, d, e, b, f, c, s) {
          a[b] =
            a[b] ||
            function() {
              a[b].q.push(arguments);
            };
          a[b].q = [];
          c = d.createElement(e);
          c.async = 1;
          c.src = '//tdn.r42tag.com/lib/' + f + '.js';
          s = d.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(c, s);
        })(window, document, 'script', '_st', '135-v1');
        _st('addTagProperties', {
          country: 'NL',
          language: 'EN',
          host: 'KL'
        });
        _st('loadTags');`;
    document.getElementsByTagName("head")[0].appendChild(script_node);
  }
};

const initRelay42forTransavia = () => {
  if (!document.getElementById(`relay42-${affiliate.name}`)) {
    window.dataLayer = window.dataLayer || [];
    addRelay42StyleSheetHV();
    const script_node = document.createElement("script");
    script_node.setAttribute("id", `relay42-${affiliate.name}`);
    script_node.innerHTML = `
      (function () {
        var relay_id = "1194-v1";
        (function (a, d, e, b, f, c, s) {
        a[b] = a[b] || function () { a[b].q.push(arguments); };
        a[b].q = []; c = d.createElement(e); c.async = 1; c.src = "//tdn.r42tag.com/lib/" + f + ".js";
        s = d.getElementsByTagName(e)[0]; s.parentNode.insertBefore(c, s);
        })
        (window, document, "script", "_st", relay_id);
        var _waq = window._waq || (window._waq = []);
        _waq.default_consent_level = { level: -1 };
        })();
      `;
    document.getElementsByTagName("head")[0].appendChild(script_node);
  }
};

const setRelay42InfoKLM = (relay42EventList) => {
  const existingRelay42TagsScriptNodes = document.getElementsByClassName(
    `relay42-tags-script-node`
  );
  Array.from(existingRelay42TagsScriptNodes).forEach((e) => e.remove());

  relay42EventList.forEach((element) => {
    let relayScript = "";
    relayScript += `_st("resetTags");`;

    if (
      element.pageStructure &&
      Array.isArray(element.pageStructure) &&
      element.pageStructure.length > 0
    ) {
      let setPageStructure = `_st("setPageStructure",`;

      element.pageStructure.forEach((structureElement, index) => {
        setPageStructure += `\"${structureElement}\"`;

        if (index < element.pageStructure.length - 1) {
          setPageStructure += `,`;
        } else {
          setPageStructure += `);`;
        }
      });

      relayScript += setPageStructure;
    }

    if (
      element.relay42Event &&
      element.relay42Event.eventData &&
      typeof element.relay42Event.eventData === "object"
    ) {
      if (window._st.tracking) {
        const sendEngagement = `_st.tracking.sendEngagement(\"${
          element.eventName
        }\", ${JSON.stringify(element.relay42Event.eventData)});`;
        relayScript += sendEngagement;
      }

      const addLocalTagProperties = `_st("addLocalTagProperties", ${JSON.stringify(
        element.relay42Event.eventData
      )});`;
      relayScript += addLocalTagProperties;
    }

    relayScript += '_st("loadTags");';
    const script_node = document.createElement("script");
    script_node.classList.add("relay42-tags-script-node");
    script_node.innerHTML = relayScript;
    document.getElementsByTagName("head")[0].appendChild(script_node);
  });
};

const setRelay42InfoTransavia = (relay42EventList) => {
  relay42EventList.forEach((element) => {
    relayScript.eventName = element.eventName;
    relayScript.eventData = element.relay42Event.eventData;
    window._waq.push(relayScript);
  });
};

const getApiPath = (eventName) => {
  return relay42constans.apiPaths[eventName];
};

const waitForTrackingAvailable = async () => {
  const waitUntil = (condition, checkInterval = 500) => {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (!condition()) return;
        clearInterval(interval);
        resolve();
      }, checkInterval);
    });
  };

  await waitUntil(() => window._st.tracking);
  return;
};

const getRelay42Info = async (eventName, eventData) => {
  const requestUrl = GetApiUrl() + getApiPath(eventName);
  let isPostRequest = false;

  if (eventData instanceof Object) {
    isPostRequest = true;
  }

  const axiosConfig = {
    method: isPostRequest ? httpRequestMethods.POST : httpRequestMethods.GET,
    url: `${requestUrl}${isPostRequest ? "" : eventData}`,
    data: eventData,
  };

  if (process.env.REACT_APP_AFFILIATE === affiliates.klm) {
    await waitForTrackingAvailable();
    const response = await axios.request(axiosConfig);
    if (response && response.data && response.data.relay42EventList) {
      setRelay42InfoKLM(response.data.relay42EventList);
    }
  } else if (process.env.REACT_APP_AFFILIATE === affiliates.transavia) {
    const response = await axios.request(axiosConfig);
    if (response && response.data && response.data.relay42EventList) {
      setRelay42InfoTransavia(response.data.relay42EventList);
    }
  }
};

export const pageEventTrigger = (eventName, eventData) => {
  if (currentPathname !== window.location.pathname) {
    currentPathname = window.location.pathname;
    getRelay42Info(eventName, eventData);
  }
};

export const customEventTrigger = (eventName, eventData) => {
  getRelay42Info(eventName, eventData);
};

export const Relay42Service = {
  relay42constans: relay42constans,
  initRelay42: initRelay42,
  pageEventTrigger: pageEventTrigger,
  customEventTrigger: customEventTrigger,
};
