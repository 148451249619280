import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  GoogleMap,
  MarkerF,
  useJsApiLoader,
  InfoBox,
  StreetViewPanorama,
  MarkerClustererF,
} from "@react-google-maps/api";
import "./hotel-list-map.component.scss";
import "./../../common/colours.scss";
import { PriceMarkerComponent } from "./price-marker/price-marker.component";
import InfoWindowContentComponent from "./info-window/info-window-content.component";
import config from "./../../environments/config.json";
import { breakpoints } from "../../common/constants/breakpoints";
import { useWindowSizeDetector } from "../../hooks/useWindowSizeDetector";
import { GoogleMapConst } from "../../common/enums/googleMap";
import {
  clusterStylesForKLM,
  clusterStylesForTransavia,
} from "./clusters-styling";
import { affiliates } from "../../common/enums/affiliates";
import { GetAffiliate } from "../../services/general.service";

const HotelListMapComponent = ({
  fullHolidaysData,
  isMapExtended,
  contentfulButtons,
  singleHotelData,
  mapClickedFromHolidayCard,
}) => {
  const mapRef = useRef(null);
  const containerRef = useRef(null);
  const [center, setCenter] = useState(null);
  const [markers, setMarkers] = useState(null);
  const [zoom, setZoom] = useState(5);
  const [activeMarker, setActiveMarker] = useState(null);
  const [fullScreen, setFullScreen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const windowSizeDetector = useWindowSizeDetector();

  const options = {
    disableDefaultUI: true,
    zoomControl: true,
    mapTypeControl: true,
    mapTypeControlOptions: {
      style: window?.google?.maps?.MapTypeControlStyle?.HORIZONTAL_BAR,
      position: window?.google?.maps?.ControlPosition?.BOTTOM_LEFT,
    },
    controlSize: 24,
    streetViewControl: true,
  };

  useEffect(() => {
    setActiveMarker(null);
    setMarkers(fullHolidaysData.data)
    calculateMapZoomAndBounds();
  }, [fullHolidaysData.data])

  const extendBounds = useCallback(() => {
    const bounds = new window.google.maps.LatLngBounds();
    markers?.forEach((holiday) => {
      bounds.extend(holiday.position);
    });
    return bounds;
  }, [markers]);
  
  const calculateMapZoomAndBounds = useCallback(() => {
    if (window.google && window.google.maps) {
      if (markers?.length > 0) {
        const bounds = extendBounds();
  
        const centerPoint = {
          lat: (bounds.getNorthEast().lat() + bounds.getSouthWest().lat()) / 2,
          lng: (bounds.getNorthEast().lng() + bounds.getSouthWest().lng()) / 2,
        };
  
        setCenterForMarker(centerPoint);
        const zoomLevel = calculateZoomLevel(bounds);
        setZoom(zoomLevel);
      }
    }
  },[markers]);

  const onLoad = () => {
    calculateMapZoomAndBounds();
  };

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: config.GM_KEY,
  });

  useEffect(() => {
    windowSizeDetector.width < breakpoints.SM
      ? setIsMobile(true)
      : setIsMobile(false);
  }, [windowSizeDetector]);

  useEffect(() => {
    setMarkers(fullHolidaysData.data);
    if (singleHotelData && singleHotelData.hotel?.id) {
      const selectedPlaceFromHolidaysCard = {
        hotelId: singleHotelData.hotel?.id,
        hotelName: singleHotelData.hotel?.name,
        discountPerPerson: singleHotelData.summary?.discountPerPerson,
        holidayPageLink: singleHotelData?.holidayPageLink,
        imageUrl: singleHotelData.hotel?.images[0],
        position: singleHotelData.hotel?.location?.position,
        pricePerPerson: singleHotelData.summary?.pricePerPerson,
        rating: singleHotelData.hotel?.rating,
      };
      const position = singleHotelData.hotel?.location?.position;
      const centerPoint = { lat: position.lat, lng: position.lng };
      setCenterForMarker(centerPoint);
      setActiveMarker(selectedPlaceFromHolidaysCard);
      setZoom(GoogleMapConst.ZOOM.DEFAULT);
    }
  }, [
    fullHolidaysData,
    markers,
    singleHotelData,
    mapClickedFromHolidayCard,
    zoom,
  ]);
  const setCenterForMarker = (marker) => {
    const mapRefZoom = mapRef.current?.state?.map?.zoom;
    switch (mapRefZoom) {
      case GoogleMapConst.ZOOM.LEVEL_14:
        setCenter({lat: marker.lat + GoogleMapConst.ZOOM_FRACTIONS.LEVEL_14, lng: marker.lng});
        break;
      case GoogleMapConst.ZOOM.LEVEL_15:
        setCenter({lat: marker.lat + GoogleMapConst.ZOOM_FRACTIONS.LEVEL_15, lng: marker.lng });
        break;
      case GoogleMapConst.ZOOM.LEVEL_16:
        setCenter({lat: marker.lat + GoogleMapConst.ZOOM_FRACTIONS.LEVEL_16, lng: marker.lng});
        break;
      case GoogleMapConst.ZOOM.LEVEL_17:
        setCenter({lat: marker.lat + GoogleMapConst.ZOOM_FRACTIONS.LEVEL_17, lng: marker.lng});
        break;
      case GoogleMapConst.ZOOM.LEVEL_18:
      case GoogleMapConst.ZOOM.LEVEL_19:
        setCenter({lat: marker.lat + GoogleMapConst.ZOOM_FRACTIONS.LEVEL_18_AND_19, lng: marker.lng});
        break;
      case GoogleMapConst.ZOOM.LEVEL_20:
        setCenter({lat: marker.lat + GoogleMapConst.ZOOM_FRACTIONS.LEVEL_20, lng: marker.lng});
        break;
      case GoogleMapConst.ZOOM.MAX:
        setCenter({lat: marker.lat + GoogleMapConst.ZOOM_FRACTIONS.LEVEL_21, lng: marker.lng});
        break;
      default:
        setCenter({lat: marker.lat, lng: marker.lng});
        break;
    }
  };

  const onMarkerClick = (marker) => {
    setCenterForMarker(marker.position);
    setActiveMarker(marker);
  };

  const onMapClick = () => {
    setActiveMarker(null);
  };

  if (loadError) {
    return <div>Unable to load Google Maps API</div>;
  }

  const handleOnClose = () => {
    isMapExtended(!isMapExtended);
  };

  const calculateZoomLevel = (bounds) => {
    if (activeMarker) {
      return GoogleMapConst.ZOOM.DEFAULT;
    } else {
      const worldDimensions = { height: 256, width: 384 };
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();
      const latFraction = (ne.lat() - sw.lat()) / 360;
      const lngFraction = (ne.lng() - sw.lng()) / 360;
      const latZoomFractial = Math.log2(
        worldDimensions.height / 256 / latFraction
      );
      const lngZoomFractial = Math.log2(
        worldDimensions.width / 256 / lngFraction
      );
      const latZoom = Math.round(latZoomFractial);
      const lngZoom = Math.round(lngZoomFractial);
      const calculatedZoom = Math.min(latZoom, lngZoom);
      return recalculateZoomLevel(
        calculatedZoom,
        lngZoomFractial,
        latZoomFractial
      );
    }
  };

  const recalculateZoomLevel = (
    calculatedZoom,
    lngZoomFractial,
    latZoomFractial
  ) => {
    if (Math.round(Math.abs(lngZoomFractial - latZoomFractial) !== 0)) {
      if (isMobile) {
        return calculatedZoom - 1;
      }
      return calculatedZoom;
    }
    if (
      calculatedZoom === GoogleMapConst.ZOOM.DEFAULT ||
      Math.floor(latZoomFractial) - Math.floor(lngZoomFractial) === 0 ||
      isMobile
    ) {
      return calculatedZoom - 1;
    }
    return calculatedZoom;
  };

  const handleFullscreenClick = () => {
    if (!isMobile) {
      const mapContainer = containerRef.current;
      setFullScreen(!fullScreen);

      if (!fullScreen) {
        if (mapContainer.requestFullscreen) {
          mapContainer.requestFullscreen();
        } else if (mapContainer.mozRequestFullScreen) {
          mapContainer.mozRequestFullScreen();
        } else if (mapContainer.webkitRequestFullscreen) {
          mapContainer.webkitRequestFullscreen();
        } else if (mapContainer.msRequestFullscreen) {
          mapContainer.msRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
    }
  };
  const screenSizeMapStyle = (() => {
    if (fullScreen) {
      return "map-container-full-screen-style";
    }
    if (isMobile) {
      return "map-container-mobile-style";
    }
    return "map-container-style";
  })();

  const getStylesForCluster = () => {
    const styles =
      GetAffiliate() === affiliates.klm
        ? clusterStylesForKLM
        : clusterStylesForTransavia;
    return styles.default;
  };

  return (
    <div className="hotel-list-map-component" ref={containerRef}>
      {isMobile && (
        <div className="maps-button-container">
          <div onClick={handleOnClose} className="button">
            {contentfulButtons[0]?.fields?.cancel}
          </div>
        </div>
      )}
      {isLoaded && (
        <>
          {!isMobile && (
            <>
              <button onClick={handleOnClose} className="map-button-close">
                {contentfulButtons && contentfulButtons[0]?.fields?.cancel}
                <div className="close-icon"></div>
              </button>
              <div className="full-screen-map">
                <button
                  className={`${
                    !fullScreen
                      ? "full-screen-map-button"
                      : "full-screen-map-button-close"
                  }`}
                  onClick={handleFullscreenClick}
                ></button>
              </div>
            </>
          )}
          <GoogleMap
            mapContainerClassName={screenSizeMapStyle}
            zoom={zoom}
            center={center}
            options={options}
            onClick={onMapClick}
            onLoad={(mapValue) => onLoad(mapValue)}
            ref={mapRef}
          >
            <MarkerClustererF
              options={{
                averageCenter: true,
                styles: getStylesForCluster(),
              }}
              onClick={onMapClick}
              clusterClass="cluster-class"
            >
              {(clusterer) =>
                markers?.map((marker, index) => (
                  <MarkerF
                    key={marker.hotelId + Math.random()}
                    icon={{
                      url: PriceMarkerComponent({
                        price: marker.pricePerPerson,
                      }),
                    }}
                    position={marker.position}
                    hotelName={marker.name}
                    pricePerPersonFrom={marker.pricePerPerson}
                    image={marker.imageUrl}
                    starRating={marker.rating.starRating}
                    tripAdvisorRating={marker.rating.tripAdvisorRating}
                    onClick={() => onMarkerClick(marker)}
                    clusterer={clusterer}
                  />
                ))
              }
            </MarkerClustererF>

            {activeMarker && (
              <div className="infoBox-wrapper">
                <InfoBox
                  position={activeMarker.position}
                  onCloseClick={() => {
                    setActiveMarker(null);
                  }}
                  options={{
                    pixelOffset: { width: -120, height: -30 },
                    closeBoxURL: "",
                    boxClass: "infoBox",
                    isHidden: false,
                    pane: "floatPane",
                    enableEventPropagation: false,
                    alignBottom: true,
                  }}
                  visible={true}
                >
                  <>
                    <div className="info-marker-container">
                      <img
                        src={activeMarker.imageUrl}
                        alt={activeMarker.hotelName}
                      />
                      <InfoWindowContentComponent
                        activeMarker={activeMarker}
                        contentfulButtons={contentfulButtons}
                      />
                    </div>
                    <div className="arrow-indicator"></div>
                  </>
                </InfoBox>
              </div>
            )}
            <StreetViewPanorama />
          </GoogleMap>
        </>
      )}
    </div>
  );
};

export default HotelListMapComponent;
