import { Fragment } from "react";
import {
  cabin_luggage as CabinLuggageIconKLM,
  cabin_luggage_icon_warning as CabinLuggageWarningIconKLM,
} from "../../../assets/icons/klm/index";
import {
  cabin_luggage as CabinLuggageIconTransavia,
  cabin_luggage_icon_warning as CabinLuggageWarningIconTransavia,
} from "../../../assets/icons/transavia/index";
import { affiliates } from "../../../common/enums/affiliates";
import { renderDocument } from "../../../services/contentful.service";
import { GetAffiliate } from "../../../services/general.service";
import "./cabin-luggage.component.scss";

export const CabinBaggageCard = ({ luggageData }) => {
  if (!luggageData || luggageData.length === 0) return
  
  const affiliate = GetAffiliate();
  const cabinBaggageData = luggageData[0]?.fields;

  const displayCabinBaggageConditionDetails = (data) => {
    if (data) {
      return data.split("\n").map((info, index) => (
        <Fragment key={index}>
          {info}
          <br />
        </Fragment>
      ));
    }
  };

  const displayCabinLuggageIcon = () => {
    return affiliate === affiliates.klm ? (
      <img alt="KLMIcon" src={CabinLuggageIconKLM} />
    ) : (
      <img alt="TransaviaIcon" src={CabinLuggageIconTransavia} />
    );
  };

  const displayCabinLuggageWarningIcon = () => {
    return affiliate === affiliates.klm? (
      <img alt="KLMWarningIcon" src={CabinLuggageWarningIconKLM} />
    ) : (
      <img alt="TransaviaWarningIcon" src={CabinLuggageWarningIconTransavia} />
    );
  };

  return (
    <>
      {cabinBaggageData?.cabinBaggageConditions && (
        <div className="cabin-baggage-card-component ">
          <div className="cabin-luggage-section-header">
            {luggageData && cabinBaggageData?.cabinBaggageHeader}
          </div>
          <div className="cabin-baggage-card-info">
            <div className="cabin-baggage-card-details">
              <div className="cabin-baggage-card-description">
                <div className="cabin-baggage-card-title">
                  {luggageData && cabinBaggageData?.cabinBaggageLabel}
                </div>
                <div className="cabin-baggage-card-condition-details">
                  {luggageData &&
                    displayCabinBaggageConditionDetails(
                      cabinBaggageData?.cabinBaggageConditions
                    )}
                </div>
              </div>
              <div className="cabin-baggage-card-img">
                {displayCabinLuggageIcon()}
              </div>
            </div>
            <div className="cabin-baggage-card-warning">
              <div className="cabin-baggage-card-warning-icon">
                {displayCabinLuggageWarningIcon()}
              </div>
              <div className="cabin-baggage-card-warning-info">
                {luggageData &&
                  renderDocument(cabinBaggageData?.cabinBaggageAlert)}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
