import { useEffect, useState } from "react";
import { useWindowSizeDetector } from "../../../hooks/useWindowSizeDetector";
import { breakpoints } from "../../../common/constants/breakpoints";
import { filterType } from "../../../common/constants/filterType";
import { FilterChipComponent } from "./filter-chip/filter-chip-component";
import { methods } from "../filters.methods";
import "./selected-filters.component.scss";
import { useSearchParams } from "react-router-dom";
import { LocalStorageService } from "../../../services/local-storage.service";
import { LocalStorageEnum } from "../../../common/enums/localStorageEnum";
import { searchParamsEnum } from "../../../common/enums/searchParamsEnum";

export const SelectedFilterComponent = ({
  holidayFullData,
  contentfulResultsPageResponse,
  contentfulButtons,
  contentfulFilters,
  performSearch,
  performFilter,
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [holidayListKey, setStateHolidaysListKey] = useState("");
  const [destinationFilterChips, setStateDestinationFilterChips] =
    useState(null);
  const [genericFilterChips, setStateGenericFilterChips] = useState(null);
  const [requestedFilters, setStateRequestedFilters] = useState(null);
  const [numberOfFiltersApplied, setStateNumberOfFiltersApplied] =
    useState(null);
  const windowSize = useWindowSizeDetector();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    windowSize.width > breakpoints.SM ? setIsMobile(false) : setIsMobile(true);
    windowSize.width > breakpoints.SM && windowSize.width < breakpoints.XL
      ? setIsTablet(true)
      : setIsTablet(false);
  });

  useEffect(() => {
    const destinationFilterValues =
      holidayFullData?.data?.filterInfo?.destinationFilterChips
        .map((destination) => {
          return destination.destinationCode;
        })
        .join(",");

    if (destinationFilterValues?.length > 0) {
      setSearchParams((params) => {
        params.set(
          searchParamsEnum.destinationLocation,
          destinationFilterValues
        );
        return params;
      });
      LocalStorageService.setLocalStorageItem(
        LocalStorageEnum.DESTINATION_LOCATIONS,
        destinationFilterValues
      );
    } else {
      setSearchParams((params) => {
        params.delete(searchParamsEnum.destinationLocation);
      });
      setSearchParams(searchParams.toString());
      LocalStorageService.setLocalStorageItem(
        LocalStorageEnum.DESTINATION_LOCATIONS,
        ""
      );
    }
  }, [holidayFullData]);

  useEffect(() => {
    setStateHolidaysListKey(holidayFullData?.data?.holidayListKey);
    setStateDestinationFilterChips(
      holidayFullData?.data?.filterInfo?.destinationFilterChips
    );
    setStateGenericFilterChips(
      holidayFullData?.data?.filterInfo?.genericFilterChips
    );
    setStateRequestedFilters(
      holidayFullData?.data?.filterInfo?.requestedFilters
    );
    setStateNumberOfFiltersApplied(
      holidayFullData?.data?.filterInfo?.numberOfFiltersApplied
    );
  }, [
    holidayFullData,
    holidayListKey,
    contentfulButtons,
    contentfulResultsPageResponse,
    requestedFilters,
  ]);

  const performFilterAction = (filter) => {
    if (filter && filter.type === filterType.destination) {
      if (filter.value === null) {
        setSearchParams((params) => {
          params.delete(searchParamsEnum.destinationLocation);
        });
        setSearchParams(searchParams.toString());
        performSearch(methods.setSearchRequestBody(holidayFullData, null, ""));
        return;
      } else {
        const filteredDestinations = destinationFilterChips
          .map((destination) => {
            if (destination.destinationCode !== filter.value) {
              return destination.destinationCode;
            }
          })
          .filter((destination) => destination !== undefined)
          .join(",");

        if (filteredDestinations.length === 0) {
          setSearchParams((params) => {
            params.set(searchParamsEnum.destinationLocation, "");
            return params;
          });
          setSearchParams(searchParams.toString());
        } else {
          setSearchParams((params) => {
            params.set(
              searchParamsEnum.destinationLocation,
              filteredDestinations
            );
            return params;
          });
        }
      }
      performSearch(
        methods.setSearchRequestBody(holidayFullData, requestedFilters, filter)
      );
    } else if (filter && filter.type === filterType.generic) {
      performFilter(
        methods.setDeleteFilterRequestBody(
          holidayListKey,
          requestedFilters,
          filter
        )
      );
    } else {
      if (filter.action === "delete") {
        setSearchParams((params) => {
          const destinationParams = params
            .get(searchParamsEnum.destinationLocation)
            .split(",")
            .filter((param) => param != filter.value)
            .join(",");

          params.set(searchParamsEnum.destinationLocation, destinationParams);
          LocalStorageService.setLocalStorageItem(
            LocalStorageEnum.DESTINATION_LOCATIONS,
            destinationParams
          );
          return params;
        });
      }
      performSearch(methods.setSearchRequestBody(holidayFullData));
    }
  };

  return (
    <div className="selected-filters-component">
      {!isTablet && !isMobile && (
        <div className="selected-filters-header">
          {numberOfFiltersApplied || 0}{" "}
          {contentfulFilters && contentfulFilters[0]?.fields?.filtersSelected}
        </div>
      )}
      <div className="selected-filters-container">
        {destinationFilterChips?.map((filter, index) => (
          <FilterChipComponent
            key={index}
            data={filter}
            contentfulFilters={contentfulFilters}
            performFilterAction={performFilterAction}
          />
        ))}
        {genericFilterChips?.map((filter, index) => (
          <FilterChipComponent
            key={index}
            data={filter}
            contentfulFilters={contentfulFilters}
            performFilterAction={performFilterAction}
          />
        ))}
        <div
          onClick={() =>
            performFilterAction({
              type: "destination",
              value: null,
              text: "",
              action: "delete",
            })
          }
          className="delete-all-filter-chips"
        >
          {contentfulButtons?.length > 0 &&
            contentfulButtons[0]?.fields?.clearAllFilters}
        </div>
      </div>
    </div>
  );
};
