export const LocalStorageEnum = {
  ROOM_ALLOCATION: "roomAllocation",
  HOTEL_ID: "hotelId",
  HOTEL_DATA: "hotelData",
  HOLIDAYS_CULTURE: "HOLIDAYS_CULTURE",
  DESTINATION_LOCATIONS: "destinationLocations",
  DESTINATION_AIRPORT_CODE: "destinationAirportCode",
  DEPARTURE_LOCATION: "departureLocation",
  SELECTED_DATES: "selectedDates",
  SEARCH_TYPE: "searchType",
  LAST_HOLIDAY_KEY: "lastHolidayKey",
  PASSENGER_DETAILS: "passengerDetails",
  CONTENTFUL_ENTRIES: "contentfulEntries",
  HOTEL_AMENITIES: "hotelAmenities",
  GENERAL_DESCRIPTION: "generalDescription",
  CHECK_IN_INSTRUCTION: "hotelDescription_checkInInstruction",
  AMENITIES_DESCRIPTION: "hotelDescription_amenitiesDescription",
  KNOW_BEFORE_YOU_GO_DESCRIPTION: "hotelDescription_knowBeforeYouGoDescription",
  MANDATORY_FEE_DESCRIPTION: "hotelDescription_mandatoryFeeDescription",
};
