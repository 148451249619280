import React, { useState } from "react";
import { GetAffiliate } from "../../services/general.service";
import { ReactComponent as IconInfo } from "../../assets/icons/klm/icon-information.svg";
import Tooltip from "../tooltip/tooltip";
import { affiliates } from "../../common/enums/affiliates";
import { ShoppingCartTooltipEnums } from "../../common/enums/shoppingCartTooltipEnums";

export const PriceOverviewComponent = ({
  data,
  insurance,
  contentfulHolidayPageSummary,
}) => {
  const {
    bookingCost,
    calamityFund,
    discount,
    guaranteeFundPerTraveller,
    guaranteeFundQuantity,
    travellers,
    pricePerTraveller,
    rentalCarCost,
    transferCost,
    baggageCost,
    paymentCost,
    voucherDiscount,
    insuranceCost,
  } = data;

  const [tooltipVisible, setTooltipVisible] = useState({});

  const handleMouseOver = (key) => {
    setTooltipVisible((prevState) => ({ ...prevState, [key]: true }));
  };

  const handleMouseOut = (key) => {
    setTooltipVisible((prevState) => ({ ...prevState, [key]: false }));
  };

  return (
    <div className="price-overview-component">
      <div className="shopping-cart-primary-header">
        {contentfulHolidayPageSummary &&
          contentfulHolidayPageSummary[0]?.fields?.priceOverview}
      </div>

      {pricePerTraveller !== "" && (
        <div className="content-item">
          <div>
            {contentfulHolidayPageSummary &&
              contentfulHolidayPageSummary[0]?.fields?.traveller}{" "}
            ({"x" + travellers})
          </div>{" "}
          <div className="content-item-price">€ {pricePerTraveller}</div>
        </div>
      )}

      {discount !== "" && (
        <div className="content-item klm-green">
          {discount === "" ? null : (
            <>
              <div>
                {contentfulHolidayPageSummary &&
                  contentfulHolidayPageSummary[0]?.fields
                    ?.holidayPageSummaryExtension?.packageHolidayDiscount}
              </div>{" "}
              <div className="content-item-price">- € {discount}</div>
            </>
          )}
        </div>
      )}

      {/* Voucher Code Discount */}
      {voucherDiscount !== "" && (
        <div className="content-item klm-green">
          {voucherDiscount === "" ? null : (
            <>
              <div>
                {contentfulHolidayPageSummary &&
                  contentfulHolidayPageSummary[0]?.fields
                    ?.holidayPageSummaryExtension?.voucherCode}
              </div>{" "}
              <div className="content-item-price">- € {voucherDiscount}</div>
            </>
          )}
        </div>
      )}

      {/* Baggage price */}
      {baggageCost && (
        <div className="content-item">
          <div>
            {contentfulHolidayPageSummary &&
              contentfulHolidayPageSummary[0]?.fields?.bagage}
          </div>{" "}
          <div className="content-item-price">€ {baggageCost}</div>
        </div>
      )}

      {rentalCarCost && (
        <div className="content-item">
          <div>
            {contentfulHolidayPageSummary &&
              contentfulHolidayPageSummary[0]?.fields
                ?.holidayPageSummaryExtension?.auto}
          </div>{" "}
          <div className="content-item-price">€ {rentalCarCost}</div>
        </div>
      )}

    {transferCost && (
        <div className="content-item">
          <div>
            {contentfulHolidayPageSummary &&
              contentfulHolidayPageSummary[0]?.fields
                ?.holidayPageSummaryExtension?.transfers}
          </div>{" "}
          <div className="content-item-price">€ {transferCost}</div>
        </div>
      )}

      {/* Insurance */}
      {insuranceCost && (
        <div className="content-item">
          <div>
            {contentfulHolidayPageSummary &&
              contentfulHolidayPageSummary[0]?.fields?.insurance.title}
          </div>{" "}
          <div className="content-item-price">€ {insuranceCost}</div>
        </div>
      )}

      {guaranteeFundPerTraveller !== "" && (
        <div className="content-item">
          <div>
            {contentfulHolidayPageSummary &&
              contentfulHolidayPageSummary[0]?.fields?.sgr}{" "}
            (x{guaranteeFundQuantity}){" "}
            <div
              className="content-item-icon"
              onMouseOver={() => handleMouseOver(ShoppingCartTooltipEnums.SGR)}
              onMouseOut={() => handleMouseOut(ShoppingCartTooltipEnums.SGR)}
            >
              <IconInfo className="icon-inform" />
              {GetAffiliate() === affiliates.klm
                ? tooltipVisible["SGR"] && (
                    <Tooltip
                      header={
                        contentfulHolidayPageSummary &&
                        contentfulHolidayPageSummary[0]?.fields?.sgrTitle
                      }
                      body={
                        contentfulHolidayPageSummary &&
                        contentfulHolidayPageSummary[0]?.fields?.sgrContent
                      }
                    />
                  )
                : GetAffiliate() === affiliates.transavia
                ? tooltipVisible[ShoppingCartTooltipEnums.SGR] && (
                    <Tooltip
                      header={
                        contentfulHolidayPageSummary &&
                        contentfulHolidayPageSummary[0]?.fields?.sgrTitle
                      }
                      body={
                        contentfulHolidayPageSummary &&
                        contentfulHolidayPageSummary[0]?.fields?.sgrContent
                      }
                    />
                  )
                : null}
            </div>
          </div>
          <div className="content-item-price">
            € {guaranteeFundPerTraveller}
          </div>
        </div>
      )}

      {calamityFund !== "" && (
        <div className="content-item">
          <div>
            {contentfulHolidayPageSummary &&
              contentfulHolidayPageSummary[0]?.fields?.calamityFund}
            <div
              className="content-item-icon"
              onMouseOver={() =>
                handleMouseOver(ShoppingCartTooltipEnums.Calamityfund)
              }
              onMouseOut={() =>
                handleMouseOut(ShoppingCartTooltipEnums.Calamityfund)
              }
            >
              <IconInfo className="icon-inform" />
              {tooltipVisible[ShoppingCartTooltipEnums.Calamityfund] && (
                <Tooltip
                  header={
                    contentfulHolidayPageSummary &&
                    contentfulHolidayPageSummary[0]?.fields?.calamityFund
                  }
                  body={
                    contentfulHolidayPageSummary &&
                    contentfulHolidayPageSummary[0]?.fields
                      ?.calamiteitenfondsContent
                  }
                />
              )}
            </div>
          </div>{" "}
          <div className="content-item-price">€ {calamityFund}</div>
        </div>
      )}

      {bookingCost !== "" && (
        <div className="content-item">
          <div>
            {" "}
            {contentfulHolidayPageSummary &&
              contentfulHolidayPageSummary[0]?.fields?.bookingCost}
          </div>{" "}
          <div className="content-item-price">€ {bookingCost}</div>
        </div>
      )}

      {paymentCost !== "" && (
        <div className="content-item">
          <div>
            {contentfulHolidayPageSummary &&
              contentfulHolidayPageSummary[0]?.fields?.cardFeesLable}
          </div>
          <div className="content-item-price">€ {paymentCost}</div>
        </div>
      )}
    </div>
  );
};
