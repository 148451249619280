import { configureStore, getDefaultMiddleware  } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import holidaySlice from "./holiday/slice";
import { modalSlice } from './modal/slice';
import shoppingCartSlice from './shopping/slice';
import checkOutSlice from './shopping/slice';
import holidayHeaderSlice from './holidayHeader/slice';
import roomSlice from './rooms/slice';
import { selectInsurance } from './shopping/slice';
import contentfulSlice from './contentful/slice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['holidayData', 'shoppingCart', 'checkOut', 'roomsData', 'holidayHeader']
};

const rootReducer = combineReducers({
    holidayData: holidaySlice.reducer,
    modalData: modalSlice.reducer,
    shoppingCart: shoppingCartSlice.reducer,
    checkOut: checkOutSlice.reducer, 
    roomsData: roomSlice.reducer,
    holidayHeader: holidayHeaderSlice.reducer,
    contentful: contentfulSlice.reducer
  });

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [selectInsurance.fulfilled.type, 'persist/PERSIST']
    },
  })
});

export const persistor = persistStore(store);