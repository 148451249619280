export const PagesConstant = {
  SEARCH: "search",
  RESULTS: "results",
  HOLIDAY: "holiday",
  EXTRAS: "extras",
  CHECKOUT: "checkout",
  PAYMENT: "payment",
  CONFIRMATION: "confirmation",
  BASEPATH: "/app",
};

export const MAIN_ROUTE = "";
