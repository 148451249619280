import { useContext, useEffect, useRef, useState } from "react";
import { getContentfulByContentType } from "../../services/contentful.service";
import { contentType } from "../../common/constants/contentType";
import { sortType } from "../../common/constants/sortType";
import ContentfulContext from "../../store/contentful/contentful-context";
import { pagination } from "../../common/constants/pagination";

export const HolidayListSortComponent = ({ holidayListKey, pageAndSortInfo, performSort }) => {
    const contentfulEntries = useContext(ContentfulContext);
    const holidayListSortComponent = useRef(null);
    const [contentfulSort, setStateContentfulSort] = useState(null);
    const [showAvailableSortTypes, setStateShowAvailableSortTypes] = useState(false);
    const [selectedSortType, setStateSelectedSortType] = useState("default");

    useEffect(() => {
        const getContentfulSort = getContentfulByContentType(
            contentfulEntries,
            contentType.SORT
        );
        setStateContentfulSort(getContentfulSort && getContentfulSort[0]?.fields);
    }, [contentfulEntries]);

    useEffect(() => {
        const clickEventHandler = (event) => {
            if (
                holidayListSortComponent.current &&
                !holidayListSortComponent.current.contains(event.target)
            ) {
                setStateShowAvailableSortTypes(false);
            }
        };

        document.addEventListener("click", clickEventHandler);
        return (() => document.removeEventListener("click", clickEventHandler));
    }, [holidayListSortComponent]);

    const setShowAvailableSortTypes = () => {
        setStateShowAvailableSortTypes(!showAvailableSortTypes);
    };

    const getContentfulFieldValue = (input) => {
        const sortTypeValue = sortType[input];
        const contentfulFieldValue = contentfulSort
            ? contentfulSort[sortTypeValue]
                ? contentfulSort[sortTypeValue]
                : null
            : null;
        return contentfulFieldValue;
    };

    const setSortRequestBody = (sortType) => {
        const sortRequestBody = {
            holidayListKey: holidayListKey,
            pageAndSorting: {
                sortType: sortType,
                pageNumber: pagination.firstPage
            }
        };

        setStateSelectedSortType(sortType);
        setStateShowAvailableSortTypes(false);
        getContentfulFieldValue(sortType);
        return sortRequestBody;
    };

    const selectedSortTypeContainer = (
        <div className="selected-sort-type-container">
            <div>{contentfulSort?.sortBy}</div>
            <div>{getContentfulFieldValue(selectedSortType)}</div>
            <div>
                <div className="icon">
                    <div
                        className={
                            showAvailableSortTypes
                                ? "icon-drop-up"
                                : "icon-drop-down"
                        }
                    ></div>
                </div>
            </div>
        </div>
    );

    const availableSortTypesContainer = (
        <div className="available-sort-types-container">
            {
                pageAndSortInfo?.sortOptions?.map((e, i) => {
                    return (
                        <div
                            className={
                                getContentfulFieldValue(selectedSortType) === getContentfulFieldValue(e.sortType)
                                ? "sort-type selected"
                                : "sort-type"
                            }
                            onClick={() => { performSort(setSortRequestBody(e.sortType)) }}
                            key={i}
                        >
                            <div>{getContentfulFieldValue(e.sortType)}</div>
                        </div>
                    );
                })
            }
        </div>
    );

    return (
        <div
            className="holiday-list-sort-component"
            ref={holidayListSortComponent}
            onClick={setShowAvailableSortTypes}
        >
            <div>{selectedSortTypeContainer}</div>
            {
                showAvailableSortTypes
                    ? availableSortTypesContainer
                    : null
            }
        </div>
    );
}