import React, { useContext, useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch as useDispatchShoppingCart, useSelector } from "react-redux";
import { ReactComponent as BackIconKLM } from "../../assets/icons/klm/back_icon_KLM.svg";
import { ReactComponent as BackIconTransavia } from "../../assets/icons/transavia/back_icon_transavia.svg";
import { contentType } from "../../common/constants/contentType";
import { PagesConstant } from "../../common/constants/pages";
import { HomeStayerFormName } from "../../common/constants/passengerDetailsFormFields";
import { affiliates } from "../../common/enums/affiliates";
import { contentfulEntriesReducer } from "../../common/enums/contentfulEntriesReducer";
import { PagesStepperBarComponent } from "../../components/pages-stepper-bar/pages-stepper-bar.component";
import { ShoppingCartComponent } from "../../components/shopping-cart/shopping-cart.component";
import { getContentfulByContentType } from "../../services/contentful.service";
import {
  DevHelperHasPassengerDetailsData,
  DevHelperRemapPassengerData,
  DevHelperUpdateDetails, SavePassengerDetailsDataForDevHelper,
  ShowDevHelperButtons
} from "../../services/dev-helper";
import { GetAffiliate } from "../../services/general.service";
import { GoogleAnalyticsService } from "../../services/google-analytics/google-analytics.service";
import { Relay42Service } from "../../services/relay42/relay42.service";
import ContentfulContext from "../../store/contentful/contentful-context";
import {
  getCheckOutDetailsCart,
  updateBaggageSelection,
  updateTravelerAndContact,
} from "../../store/shopping/slice";
import { HomeStayerComponent } from "./home-stayer/home-stayer.component";
import "./passenger-details.component.scss";
import "./passenger-details.styles.scss";
import { TravelerDetailsComponent } from "./traveler-details/traveler-details.component";

const initialState = {
  contentfulButtons: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case contentfulEntriesReducer.CHECKOUT_PASSENGER_DETAILS:
      return { ...state, contentfulCheckoutPassengerDetails: action.payload };
    case contentfulEntriesReducer.CHECKOUT_FORM_VALIDATORS:
      return { ...state, contentfulCheckoutFormValidators: action.payload };
    case contentfulEntriesReducer.BUTTONS:
      return { ...state, contentfulButtons: action.payload };
    default:
      throw new Error("Unknown data");
  }
};

export const PassengerDetailsPageComponent = ({
  assignCheckOutData,
  setDeepLinkParams,
}) => {
  const contentfulEntries = useContext(ContentfulContext);
  const [isWaitingForNetwork, setIsWaitingForNetwork] = useState(false);
  const [
    {
      contentfulCheckoutPassengerDetails,
      contentfulCheckoutFormValidators,
      contentfulButtons,
    },
    dispatch,
  ] = useReducer(reducer, initialState);
  const holidayKey = useSelector((state) => state.holidayData.holidayKey);
  const [checkOutData, setCheckOutData] = useState(null);
  const dispatchData = useDispatchShoppingCart();
  const contentfulPassengerDetailsPageResponse = () => {
    const contentfulCheckoutPassengerDetails = getContentfulByContentType(
      contentfulEntries,
      contentType.CHECKOUT_PASSENGER_DETAILS
    );
    const contentfulCheckoutFormValidators = getContentfulByContentType(
      contentfulEntries,
      contentType.CHECKOUT_FORM_VALIDATORS
    );
    const contentfulButtons = getContentfulByContentType(
      contentfulEntries,
      contentType.BUTTONS
    );

    dispatch({
      type: contentfulEntriesReducer.CHECKOUT_PASSENGER_DETAILS,
      payload: contentfulCheckoutPassengerDetails,
    });
    dispatch({
      type: contentfulEntriesReducer.CHECKOUT_FORM_VALIDATORS,
      payload: contentfulCheckoutFormValidators,
    });
    dispatch({
      type: contentfulEntriesReducer.BUTTONS,
      payload: contentfulButtons,
    });
  };

  useEffect(() => {
    GoogleAnalyticsService.triggerGtagPageViewEvent();
  }, []);

  useEffect(() => {
    contentfulPassengerDetailsPageResponse();
  }, [contentfulEntries]);

  useEffect(() => {
    if (!isWaitingForNetwork) {
      getCheckOutDetails();
    }
  }, [holidayKey]);

  useEffect(() => {
    if (checkOutData) {
      assignCheckOutData(checkOutData);
    }
  }, [checkOutData]);

  const getCheckOutDetails = async () => {
    setIsWaitingForNetwork(true);
    const checkOutDetailsResponse = await dispatchData(
      getCheckOutDetailsCart(holidayKey)
    );
    setIsWaitingForNetwork(false);
    setCheckOutData(checkOutDetailsResponse.payload);
    setDeepLinkParams(
      checkOutDetailsResponse?.payload?.metaInfo?.navigateToExtras
    );

    Relay42Service.pageEventTrigger(
      Relay42Service.relay42constans.apiEvents.checkout,
      checkOutDetailsResponse?.payload?.checkOutKey
    );
  };

  const {
    control,
    setError,
    clearErrors,
    register,
    unregister,
    setValue,
    formState: { errors },
    handleSubmit,
    handleError,
  } = useForm({
    mode: "all",
    shouldUnregister: true,
  });

  const navigateToExtrasPage = () => {
    window.location.href = checkOutData?.metaInfo?.navigateToExtras;
  };

  const mappedMainBooker = (data) => {
    const {
      countryData: { code: countryCode, name: countryName },
      ...flattenedData
    } = data;
    const flattenedTravellerData = {
      ...flattenedData,
      countryCode,
      countryName,
    };
    const { firstName, lastName, ...mainBookerObject } = flattenedTravellerData;

    const mainBooker = {
      email: mainBookerObject.email,
      phoneNumberCountryCode: mainBookerObject.phoneNumberCountryCode,
      phoneNumberSuffix: mainBookerObject.phoneNumberSuffix,
      address: {
        street: mainBookerObject.street,
        houseNumber: mainBookerObject.houseNumber,
        houseNumberExtension: mainBookerObject.houseNumberExtension,
        zipCode: mainBookerObject.zipCode,
        city: mainBookerObject.city,
        countryCode: mainBookerObject.countryCode,
        countryName: mainBookerObject.countryName,
      },
      companyName: mainBookerObject.companyName,
      blueBizAccount: mainBookerObject?.blueBizAccount,
    };
    return mainBooker;
  };

  const mappedTravellers = (data) => {
    const travellersArray = Object.values(data);
    const travellersDataFromApi = checkOutData.travellerInfo.travellers;
    const travellersArrayMapped = travellersArray.map((traveller, index) => {
      const frequentFlyer = {
        code: traveller?.frequentFlyer?.airlineInfo.code ?? null,
        name: traveller?.frequentFlyer?.airlineInfo.name ?? null,
        loyaltyNumber: traveller?.frequentFlyer?.loyaltyNumber ?? null,
      };

      if (frequentFlyer.code === null) {
        return {
          identifier: travellersDataFromApi[index]?.travellerIdentifier,
          firstName: traveller.firstName,
          lastName: traveller.lastName,
          gender: Number(traveller.gender),
          dateOfBirth: traveller.dateOfBirth,
          frequentFlyer: null,
        };
      } else {
        return {
          identifier: travellersDataFromApi[index]?.travellerIdentifier,
          firstName: traveller.firstName,
          lastName: traveller.lastName,
          gender: Number(traveller.gender),
          dateOfBirth: traveller.dateOfBirth,
          frequentFlyer: frequentFlyer,
        };
      }
    });
    return travellersArrayMapped;
  };

  const onDevHelperClick = async () => {
    const mockData = await DevHelperRemapPassengerData(checkOutData);
    const response = await DevHelperUpdateDetails(mockData, holidayKey);

    if (response.status === 200) {
      window.location.href = `${PagesConstant.BASEPATH}/${PagesConstant.PAYMENT}/`;
    }
  };

  const onSubmit = async (data) => {
    const mainBooker = mappedMainBooker(data.traveller1);
    const homeStayer = data.homeStayer;
    delete data.homeStayer;
    const travellers = mappedTravellers(data);
    const payload = {
      travellers: travellers,
      mainBooker: mainBooker,
      homeStayer: homeStayer,
    };

    SavePassengerDetailsDataForDevHelper(payload);
    const response = await dispatchData(
      updateTravelerAndContact({ holidayKey: holidayKey, data: payload })
    );

    if (response.payload.status === 200) {
      window.location.href = `${PagesConstant.BASEPATH}/${PagesConstant.PAYMENT}/`;
    }
  };

  const onError = async (errors) => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      const firstErrorKey = errorKeys[0];
      const firstError = errors[firstErrorKey];
  
      if (firstError) {
        const firstFieldErrorKey = Object.keys(firstError)[0];
        const errorRef = firstError[firstFieldErrorKey]?.ref;
  
        if (errorRef instanceof HTMLElement) {
          errorRef.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        }
      }
    }
  };

  const onBaggageSelectionChanged = async (baggageSelection) => {
    setIsWaitingForNetwork(true);
    const newCheckoutData = await updateBaggageSelection(
      holidayKey,
      baggageSelection
    );
    setCheckOutData(newCheckoutData);
    setIsWaitingForNetwork(false);
  };

  return (
    <div className="passenger-details-page-component">
      <PagesStepperBarComponent />
      <div className="passenger-details-container">
        <div className="passenger-details-card-container">
          <div className="passenger-details-header">
            {contentfulCheckoutPassengerDetails &&
              contentfulCheckoutPassengerDetails[0]?.fields?.travelerDataTitle}
          </div>
          {ShowDevHelperButtons() && DevHelperHasPassengerDetailsData() && (
            <div onClick={onDevHelperClick} className="dev-helper-button">
              Load mock data (Regular flow will update mock data)
            </div>
          )}
          <form
            className="form-container"
            id="passengerForm"
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            {checkOutData &&
              checkOutData?.travellerInfo.travellers.map(
                (travellerInfo, index) => (
                  <TravelerDetailsComponent
                    key={travellerInfo.travellerIdentifier}
                    index={index + 1}
                    checkOutData={checkOutData}
                    name={`traveller${index + 1}`}
                    travellerInfo={travellerInfo}
                    register={register}
                    unregister={unregister}
                    setValue={setValue}
                    control={control}
                    setError={setError}
                    clearErrors={clearErrors}
                    errors={errors}
                    contentfulCheckoutPassengerDetails={
                      contentfulCheckoutPassengerDetails
                    }
                    contentfulCheckoutFormValidators={
                      contentfulCheckoutFormValidators
                    }
                  />
                )
              )}
            <div className="section-heading-text">
              {contentfulCheckoutPassengerDetails &&
                contentfulCheckoutPassengerDetails[0]?.fields?.homeStayerHeader}
            </div>

            <HomeStayerComponent
              key={HomeStayerFormName}
              name={HomeStayerFormName}
              contactDetailInfo={checkOutData?.contactDetailInfo}
              register={register}
              setValue={setValue}
              control={control}
              setError={setError}
              clearErrors={clearErrors}
              errors={errors}
              contentfulCheckoutPassengerDetails={
                contentfulCheckoutPassengerDetails
              }
              contentfulCheckoutFormValidators={
                contentfulCheckoutFormValidators
              }
            />
          </form>
          <div className="action-container">
            <div className="back-checkout" onClick={navigateToExtrasPage}>
              {GetAffiliate() === affiliates.klm ? (
                <BackIconKLM />
              ) : GetAffiliate() === affiliates.transavia ? (
                <BackIconTransavia />
              ) : null}
              {contentfulButtons && contentfulButtons[0]?.fields?.back}
            </div>
            {isWaitingForNetwork && (
              <input
                form="passengerForm"
                className="button-submit disabled"
                type="submit"
                disabled={true}
                value={
                  contentfulButtons
                    ? contentfulButtons[0]?.fields?.nextStep
                    : ""
                }
              />
            )}
            {!isWaitingForNetwork && (
              <input
                form="passengerForm"
                className="button-submit"
                type="submit"
                value={
                  contentfulButtons
                    ? contentfulButtons[0]?.fields?.nextStep
                    : ""
                }
              />
            )}
          </div>
        </div>
        <div className="shopping-cart-container">
          <ShoppingCartComponent checkOutData={checkOutData} />
        </div>
      </div>
    </div>
  );
};
