import { PassengerDetailsFormFields } from "../../../common/constants/passengerDetailsFormFields";
import { affiliates } from "../../../common/enums/affiliates";
import { parseContentfulDynamicContent } from "../../../services/contentful.service";
import { GetAffiliate } from "../../../services/general.service";
import TransaviaAvatarIcon from "./../../../assets/icons/avatar-transavia.svg";
import AvatarIcon from "./../../../assets/icons/avatar.svg";
import { BaggageDropdownComponent } from "./baggage-dropdown.component";
import "./baggage.component.scss";

export const BaggageComponent = ({
  baggageDetails,
  onBaggageSelectionChanged,
  contentfulExtrasPage,
  isLoading,
  loadingPassengerId
}) => {
  const findPreselectedItem = (currentTraveller) => {
    return currentTraveller.baggageServiceOptions.find((x) => x.isSelected);
  };

  const getTravellerTypeDescription = (travelerType, index) => {

    switch (travelerType) {
      case PassengerDetailsFormFields.ADULT:
        return parseContentfulDynamicContent(
          contentfulExtrasPage &&
          contentfulExtrasPage[0]?.fields?.adultTravelerTitle,
          ++index
        );

      case PassengerDetailsFormFields.CHILD:
        return parseContentfulDynamicContent(
          contentfulExtrasPage &&
          contentfulExtrasPage[0]?.fields?.childTravelerTitle,
          ++index
        );

      default:
        return parseContentfulDynamicContent(
          contentfulExtrasPage &&
          contentfulExtrasPage[0]?.fields?.adultTravelerTitle,
          ++index
        );
    }
  };

  return (
    <div className="baggage-component">
      <div className="baggage-container">
        {baggageDetails &&
          baggageDetails.map((currentItem, index) => {
            return (
              <div key={index} className="baggage-per-person">
                <div className="passenger-section">
                  <img
                    className="svg-avatar-icon"
                    src={
                      GetAffiliate() === affiliates.klm
                        ? AvatarIcon
                        : TransaviaAvatarIcon
                    }
                    alt="avatar-icon"
                  />
                  <div className="traveller-description">
                    {getTravellerTypeDescription(
                      currentItem.travellerType,
                      index
                    )}
                  </div>
                </div>
                <div className="bagage-selection-parent">
                  <div className="baggage-section">
                    <BaggageDropdownComponent
                      currentPassenger={currentItem}
                      preselectedValue={findPreselectedItem(currentItem)}
                      onBaggageSelectionChanged={onBaggageSelectionChanged}
                      isLoading={isLoading}
                      loadingPassengerId={loadingPassengerId}
                    />
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
