import "./transfers-card.component.scss";
import { ReactComponent as PassengerIcon } from "../../../../assets/icons/passengers-S.klm.svg";
import { ReactComponent as PassengerIconHV } from "../../../../assets/icons/transavia/Avatar.svg";
import { ReactComponent as BaggageIcon } from "../../../../assets/icons/bagage-S.svg";
import { ReactComponent as TransferIcon } from "../../../../assets/icons/klm/ic_compare_arrows.svg";
import { ReactComponent as TransferIconHV } from "../../../../assets/icons/transavia/hv_ic_compare_arrows.svg";

import { GenericButton } from "../../../../components/generic-button/generic-button";
import { GetAffiliate } from "../../../../services/general.service";
import { affiliates } from "../../../../common/enums/affiliates";

export const TransferCardComponent = ({
  transferOption,
  contentfulTransfer,
  onSelectTransfer,
}) => {
  return (
    <div className={`card-component ${transferOption.selected ? "transfer-selected" : ""}`}>
      {transferOption?.image && (
        <div className="card-image">
          <img
            className="transfer-image"
            src={transferOption?.image}
            alt={transferOption?.bookingClass}
          />
        </div>
      )}

      <div className="card-description">
        <div className="card-content-title">
          <div className="card-title">{transferOption?.bookingClass}</div>
          <div className="icon-with-description">
             {GetAffiliate() === affiliates.klm ? <PassengerIcon className="icon svg-icon"/> 
             : <PassengerIconHV className="icon svg-icon"/>}
            {transferOption?.numberOfTravellers} {contentfulTransfer?.passenger}
          </div>

          <div className="icon-with-description">
            <BaggageIcon className="icon svg-icon"/>
            {transferOption?.numberOfBags} {contentfulTransfer?.suitcases}
          </div>

          <div className="icon-with-description">
          {GetAffiliate() === affiliates.klm ? <TransferIcon className="icon svg-icon"/> 
          : <TransferIconHV className="icon svg-icon"/>}
            {contentfulTransfer?.airportAndHotel}
          </div>
        </div>
        <div className="card-action">
          <div className="card-button-container">
            {!transferOption.selected ?
              <div className="card-price-block">
                <span className="card-price-block">
                  {"+ € "} {transferOption?.price}
                </span>
                <span className="total-description">
                  {" "}
                  {contentfulTransfer?.total}
                </span>
              </div>
              : <div className="spacer"></div>
            }

            {!transferOption.selected ? (
              <GenericButton
                type="button"
                className="transfer-select-button"
                onClick={() => onSelectTransfer(transferOption.optionKey)}
              >
                {contentfulTransfer?.add}
              </GenericButton>
            ) : (
              <div className="transfer-option-selected">
                <div>
                  <div className="icon-container">
                    <div className="icon"></div>
                  </div>
                  <div className="selected">{contentfulTransfer?.selected}</div>
                </div>
                <div
                  className="deselect-transfer-option"
                  onClick={() => onSelectTransfer("")}
                >
                {contentfulTransfer?.remove}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
