import React from "react";
import { useSelector } from "react-redux";
import { GetAffiliate } from "../../services/general.service";
import { GetMealType } from "../../common/enums/meal-types";
import { affiliates } from "../../common/enums/affiliates";
import { ReactComponent as IconRoomTypeKLM } from "./../../assets/icons/klm/hotel-S.svg";
import { ReactComponent as IconRoomTypeTransavia } from "./../../assets/icons/transavia/Hotel.svg";

export const AccomodationComponent = ({
  data,
  contentfulHolidayPageSummary,
  contentfulHolidayPage
}) => {
  const { checkInDate, checkOutDate, duration, nights } = data;
  const roomStateData = useSelector((state) => state.roomsData.descriptions);

  const extractDate = (dateStr) => {
    const parts = dateStr.split(" ");
    const day = parseInt(parts[1], 10);
    const month =
      [
        "jan",
        "feb",
        "mar",
        "apr",
        "maj",
        "jun",
        "jul",
        "aug",
        "sep",
        "okt",
        "nov",
        "dec",
      ].indexOf(parts[2]) + 1;
    const year = parseInt(parts[3], 10);

    return new Date(year, month - 1, day);
  };

  const formatDate = (date) => {
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const month = [
      "jan",
      "feb",
      "mar",
      "apr",
      "maj",
      "jun",
      "jul",
      "aug",
      "sep",
      "okt",
      "nov",
      "dec",
    ][monthIndex];
    const year = date.getFullYear();
    return `di. ${day} ${month} ${year}`;
  };

  return (
    <div className="accomodation-component">
      <div className="section-header">
        {GetAffiliate() === affiliates.klm ? (
          <IconRoomTypeKLM className="svg-color" />
        ) : (
          <IconRoomTypeTransavia className="svg-color" />
        )}
        {contentfulHolidayPageSummary &&
          contentfulHolidayPageSummary[0]?.fields?.hotel}
      </div>
      <div className="accomodation-description">
        {roomStateData
          ? roomStateData.map((description, index) => (
              <div key={index}>
                  <span>{description}</span>
              </div>
            ))
          : null}
      </div>
      <div>{GetMealType(data.mealType)}</div>
      <div className="accommodation-date-info">
        {checkInDate} - {checkOutDate}
      </div>
      <div>
      {duration}{" "}
      {contentfulHolidayPageSummary &&
          contentfulHolidayPageSummary[0]?.fields?.holidayPageSummaryExtension
            .days}

      {` (${nights}`}
      {`${contentfulHolidayPage && contentfulHolidayPage[0].fields.trip.nights})`}
      
      </div>
    </div>
  );
};
