import React, { useState } from "react";
import { ReactComponent as IconSustainability } from "../../assets/icons/klm/icon-leaf.svg";
import { ReactComponent as IconInfo } from "../../assets/icons/klm/icon-information.svg";
import Tooltip from "../tooltip/tooltip";

export const Sustainability = ({
  contentfulHolidayPageSummary,
  contentfulHolidayPage,
  data,
}) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  return (
    <div className="sustainability-component">
      <div className="section-header">
        <IconSustainability />
        {contentfulHolidayPageSummary &&
          contentfulHolidayPageSummary[0]?.fields?.sustainability}
        <div
          className="content-item-icon"
          onMouseOver={() => setTooltipVisible(true)}
          onMouseOut={() => setTooltipVisible(false)}
        >
          <IconInfo className="icon-inform" />
          {tooltipVisible && (
            <Tooltip
              header={
                contentfulHolidayPageSummary &&
                contentfulHolidayPageSummary[0]?.fields?.sustainability
              }
              body={
                contentfulHolidayPageSummary &&
                contentfulHolidayPageSummary[0]?.fields?.duurzaarnheidContent
              }
            />
          )}
        </div>
      </div>
      <div className="sustainability-text">
        <div className="content-item">
          <div className="content-item-text">
            {" "}
            {contentfulHolidayPage &&
              contentfulHolidayPage[0]?.fields?.sustainabilityCost}
          </div>
          <div className="content-item-price"> € {data?.co2CompensationCost}</div>
        </div>

        <div className="content-item">
          <div className="content-item-text-discount">
            {" "}
            {contentfulHolidayPage &&
              contentfulHolidayPage[0]?.fields?.sustainabilityDiscount}
          </div>
          <div className="content-item-price discount">
            {" "}
            - € {data?.co2CompensationDiscount}
          </div>
        </div>
      </div>
    </div>
  );
};
