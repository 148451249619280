import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { GetLogoPath } from "../../services/general.service";
import { ReactComponent as ArrowDown } from "../../assets/icons/klm/arrow-down-payment.svg";
import { ReactComponent as Logo } from "../../assets/icons/klm/klm-logo-payment.svg";
import ArrowDownTransavia from "../../assets/icons/transavia/arrow-down-transavia-payment.svg";
import { affiliates } from "../../common/enums/affiliates";
import { paymentTypes } from "../../common/enums/paymentTypes";
import { GetAffiliate, removeYearFromDate } from "../../services/general.service";
import FlightDetails from "../flight-details/flight-details";

import { Tooltip } from "@mui/material";
import { useState } from "react";
import { ReactComponent as DirectLineKlmPaymentMobile } from "../../assets/icons/klm/direct-line-payment-mobile.svg";
import { ReactComponent as DirectLine } from "../../assets/icons/klm/direct-line-payment.svg";
import { ReactComponent as StopLine } from "../../assets/icons/klm/direct-line-stop.svg";
import { ReactComponent as StopLineKlmPaymentMobile } from "../../assets/icons/klm/stop-line-payment-mobile.svg";
import { ReactComponent as StopLineTransavia } from "../../assets/icons/transavia/direct-line-stop-transavia.svg";
import { ReactComponent as DirectLineTransaviaPaymentMobile } from "../../assets/icons/transavia/direct-line-transavia-payment-mobile.svg";
import { ReactComponent as DirectLineTransavia } from "../../assets/icons/transavia/direct-line-trasnavia-payment.svg";
import { ReactComponent as StopLineTransaviaPaymentMobile } from "../../assets/icons/transavia/stop-line-transavia-payment-mobile.svg";
import { breakpoints } from "../../common/constants/breakpoints";
import { useWindowSizeDetector } from "../../hooks/useWindowSizeDetector";
import "./flights-and-arrivals.component.scss";

const AIRPORT_MAX_LENGTH = 15;
const AIRPORT_START_VALUE = 0;
const AIRPORT_END_VALUE = 12;

const FlightsAndArrivals = ({
  data,
  type,
  isMobile,
  title,
  contentfulPaymentPage,
  contentfulHolidayPage,
  expandedType,
  toggleExpand,
}) => {
  const isExpandedForCurrentType = expandedType === type;
  const TRANSFER_NUMBER_NONE = 0;
  const TRANSFER_NUMBER_ONE = 1;

  const windowSizeDetector = useWindowSizeDetector();
  const [isLargeScreen, setIsLargeScreen] = useState(false);

 useEffect(() => {
    if(windowSizeDetector.width > breakpoints.XL) setIsLargeScreen(true);
  }, [windowSizeDetector.width])


  const renderStopInfo = () => {
    const NUMBER_STOP_NONE = 0;
    const stopCount = Number(data?.numberOStops) || NUMBER_STOP_NONE;

    if (data.directFlight && stopCount > NUMBER_STOP_NONE) {
      return (
        <>{contentfulHolidayPage && contentfulHolidayPage[0]?.fields?.stop}</>
      );
    }

    if (data.directFlight) {
      return (
        <>
          {contentfulPaymentPage &&
            contentfulPaymentPage[0]?.fields?.directFlight}
        </>
      );
    } else if (stopCount > NUMBER_STOP_NONE) {
      return (
        <>{contentfulHolidayPage && contentfulHolidayPage[0]?.fields?.stop}</>
      );
    }

    const transferCount = data?.numberOfTransfers || NUMBER_STOP_NONE;
    const stopWord =
      transferCount === TRANSFER_NUMBER_ONE
        ? contentfulPaymentPage && contentfulPaymentPage[0]?.fields?.transfer
        : contentfulPaymentPage && contentfulPaymentPage[0]?.fields?.transfers;

    return `${transferCount} ${stopWord}`;    
  };

  return (
    <div className="flight-container">
      <div className="header">{title}</div>
      <div className="info-container">
        <div className="left-info">
          {isMobile ? (
            <>
              <div className="mobile-line-container">
                {GetAffiliate() === affiliates.klm ? (
                  <>
                    {data?.numberOfTransfers > TRANSFER_NUMBER_NONE ? (
                      <StopLineKlmPaymentMobile />
                    ) : (
                      <DirectLineKlmPaymentMobile />
                    )}
                  </>
                ) : GetAffiliate() === affiliates.transavia ? (
                  <>
                    {data?.numberOfTransfers > TRANSFER_NUMBER_NONE ? (
                      <StopLineTransaviaPaymentMobile className="mobile-line" />
                    ) : (
                      <DirectLineTransaviaPaymentMobile className="mobile-line" />
                    )}
                  </>
                ) : null}
              </div>
            </>
          ) : (
            <>
              <p className="flight-date">{data.departureDate}</p>
              <p className="flight-time">{data.departureTime}</p>
              <p className="flight-airport">{data.departureAirport}</p>
            </>
          )}
        </div>
        <div className="middle-info">
          {isMobile ? (
            <div className="mobile-info">
              <div className="mobile-info-left">
                <div className="departure-time-date">
                  <div className="departure-time">{data.departureTime}</div>
                  <div className="departure-date">{removeYearFromDate(data.departureDate)}</div>
                </div>
                <div className="flight-duration">{`${data.durationHours}h ${data.durationMinutes}m`}</div>
                <div className="arrival-time-date">
                  <div className="arrival-time">{data.arrivalTime}</div>
                  <div className="arrival-date">{removeYearFromDate(data.arrivalDate)}</div>
                </div>
              </div>
              <div className="mobile-info-right">
                <div className="departure-airport-info">
                  {data.departureAirport}
                </div>
                <div className="transfer-number-flight">{renderStopInfo()}</div>
                <div className="arrival-airport-info">{data.arrivalAiport}</div>
              </div>
            </div>
          ) : (
            <>
                <div className="airline-logo-container">
                  {GetAffiliate() === affiliates.klm ? (
                    <img
                      src={GetLogoPath(data?.airlineCode ?? (data?.segments && data.segments.length > 0 ? data.segments[0].airlineCode : null))}

                      className="company-logo"
                      alt="Company Logo"
                    />
                  ) : GetAffiliate() === affiliates.transavia ? (
                    ""
                  ) : null}
                </div>
                <div className="flight-connector-container">
                  {GetAffiliate() === affiliates.klm ? (
                    <>
                      {data?.numberOfTransfers > TRANSFER_NUMBER_NONE ? (
                        <StopLine />
                      ) : (
                        <DirectLine />
                      )}
                    </>
                  ) : GetAffiliate() === affiliates.transavia ? (
                    <>
                      {data?.numberOfTransfers > TRANSFER_NUMBER_NONE ? (
                        <StopLineTransavia />
                      ) : (
                        <DirectLineTransavia />
                      )}
                    </>
                  ) : null}
                </div>
                <div className="duration-info-container">
                  <div className="duration-details">{renderStopInfo()}</div>
                  <div className="duration-time">{`${data.durationHours}h ${data.durationMinutes}m`}</div>
                </div>
            </>
          )}
        </div>    
        <div className="right-info">
          {isMobile ? (
            <>
              {GetAffiliate() === affiliates.klm ? (
                  <img
                  src={GetLogoPath(data?.airlineCode ?? (data?.segments && data.segments.length > 0 ? data.segments[0].airlineCode : null))}
                  className="company-logo"
                  alt="Company Logo"
                />
              ) : GetAffiliate() === affiliates.transavia ? (
                ""
              ) : null}
            </>
          ) : (
            <>
              <p className="flight-date">{data.arrivalDate}</p>
              <p className="flight-time">{data.arrivalTime}</p>
              <p className="flight-airport">{data.arrivalAiport}</p>
            </>
          )}
        </div>
      </div>
      <div className="more-info-container">
        {isExpandedForCurrentType && <FlightDetails data={data} />}
        <div className="separate-line" />
        <div className="expand" onClick={() => toggleExpand(type)}>
          {contentfulPaymentPage &&
            contentfulPaymentPage[0]?.fields?.flightDetails}
          {GetAffiliate() === affiliates.klm ? (
            <ArrowDown className={isExpandedForCurrentType ? "rotated" : ""} />
          ) : GetAffiliate() === affiliates.transavia ? (
            <img
              src={ArrowDownTransavia}
              className={isExpandedForCurrentType ? "rotated" : ""}
              alt="Arrow Down"
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

FlightsAndArrivals.propTypes = {
  type: PropTypes.oneOf([paymentTypes.FLIGHTS, paymentTypes.ARRIVALS])
    .isRequired,
  data: PropTypes.shape({
    departureAirport: PropTypes.string.isRequired,
    departureDate: PropTypes.string.isRequired,
    departureTime: PropTypes.string.isRequired,
    arrivalAiport: PropTypes.string.isRequired,
    arrivalDate: PropTypes.string.isRequired,
    arrivalTime: PropTypes.string.isRequired,
    directFlight: PropTypes.bool.isRequired,
    durationHours: PropTypes.string.isRequired,
    durationMinutes: PropTypes.string.isRequired,
    transferAirports: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  isMobile: PropTypes.bool,
};

export default FlightsAndArrivals;
