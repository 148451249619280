import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { Alert } from "@mui/material";
import { CabinBaggageCard } from "./cabin-baggage-card.component";
import "./cabin-luggage.component.scss";
import { HandBaggageCard } from "./hand-baggage-card.component";

export const CabinLuggage = ({ contentfulLuggageSelection }) => {
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="cabin-luggage-description-paragraph">{children}</p>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ),
    },
  };

  return (
    <div className="cabin-luggage-container">
      <div className="cabin-luggage-information">
        <Alert className="cabin-luggage-description" severity="info">
          {contentfulLuggageSelection &&
            documentToReactComponents(
              contentfulLuggageSelection[0]?.fields?.disclaimer,
              options
            )}
        </Alert>

        <HandBaggageCard luggageData={contentfulLuggageSelection} />
        <CabinBaggageCard luggageData={contentfulLuggageSelection} />
      </div>
    </div>
  );
};
