import { Tooltip } from "@mui/material";
import "./hotel.styles.scss";

export const HotelComponent = ({ data, maxComponentWidth }) => {
  return (
    <Tooltip title={data.hotel.name} arrow>
      <div className="hotel-name-text" style={{ maxWidth: maxComponentWidth }}>
        {data.hotel.name}
      </div>
    </Tooltip>
  );
};
