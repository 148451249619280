import React, { useState, Fragment } from "react";
import { useSelector } from "react-redux";
import { ShoppingCartTooltipEnums } from "../../common/enums/shoppingCartTooltipEnums";
import { ReactComponent as IconInfo } from "../../assets/icons/klm/icon-information.svg";
import Tooltip from "../tooltip/tooltip";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

export const PriceOverviewTotal = ({
  payNow,
  contentfulHolidayPageSummary,
  contentfulConfirmationPage,
  contentfulPaymentPage,
  newSearch,
}) => {
  const [tooltipVisible, setTooltipVisible] = useState({});
  const checkOutDataCart = useSelector((state) => state.checkOut.checkOutData);
  const localFee = checkOutDataCart?.shoppingCart?.priceInfo?.localFee;

  const handleMouseOver = (key) => {
    setTooltipVisible((prevState) => ({ ...prevState, [key]: true }));
  };

  const handleMouseOut = (key) => {
    setTooltipVisible((prevState) => ({ ...prevState, [key]: false }));
  };

  return (
    <Fragment>
      <div className="price-total">
        {(checkOutDataCart?.shoppingCart?.priceInfo?.totalPayment || localFee) && (
          <div className="content-item">
            <div>
              {contentfulHolidayPageSummary &&
                contentfulHolidayPageSummary[0]?.fields?.payNow}
            </div>
            <div className="content-item-price">
              € {checkOutDataCart?.shoppingCart?.priceInfo?.totalPayment}
            </div>
          </div>
        )}

        {checkOutDataCart?.paymentInfo?.downPaymentPossible &&
          checkOutDataCart?.paymentInfo?.downPaymentSelected && (
            <>
              {/* DOWNPAYMENT PAY LATER */}
              <div className="content-item">
                <div className="downpayment-pay-later">
                  {contentfulHolidayPageSummary &&
                    contentfulHolidayPageSummary[0]?.fields?.futurePayment}{" "}
                  {checkOutDataCart?.shoppingCart?.priceInfo?.futurePaymentDate}
                  <div
                    className="content-item-icon"
                    onMouseOver={() =>
                      handleMouseOver(ShoppingCartTooltipEnums.RestToPay)
                    }
                    onMouseOut={() =>
                      handleMouseOut(ShoppingCartTooltipEnums.RestToPay)
                    }
                  >
                    <IconInfo className="icon-inform" />
                    {tooltipVisible[ShoppingCartTooltipEnums.RestToPay] && (
                      <Tooltip
                        header={
                          contentfulHolidayPageSummary &&
                          contentfulPaymentPage[0].fields.downPaymentTooltipHeader
                        }
                        body={
                          contentfulHolidayPageSummary &&
                          documentToReactComponents(
                            contentfulPaymentPage[0].fields.downPaymentTooltipBody
                          )
                        }
                      />
                    )}
                  </div>
                </div>{" "}
                <div className="content-item-price">
                  €{" "}
                  {
                    checkOutDataCart?.shoppingCart?.priceInfo
                      ?.totalFuturePayment
                  }
                </div>
              </div>
            </>
          )}

        {/* LOCAL FEE */}
        {localFee && (
          <div className="content-item">
            <div>
              {contentfulHolidayPageSummary &&
                contentfulHolidayPageSummary[0]?.fields?.localfees}
              <div
                className="content-item-icon"
                onMouseOver={() =>
                  handleMouseOver(ShoppingCartTooltipEnums.LocalFee)
                }
                onMouseOut={() =>
                  handleMouseOut(ShoppingCartTooltipEnums.LocalFee)
                }
              >
                <IconInfo className="icon-inform" />
                {tooltipVisible[ShoppingCartTooltipEnums.LocalFee] && (
                  <Tooltip
                    header={
                      contentfulHolidayPageSummary &&
                      contentfulHolidayPageSummary[0]?.fields?.betalen
                    }
                    body={
                      contentfulHolidayPageSummary &&
                      contentfulHolidayPageSummary[0]?.fields?.plaatseContent
                    }
                  />
                )}
              </div>
            </div>{" "}
            <div className="content-item-price">€ {localFee}</div>
          </div>
        )}

        {/* TOTAL PRICE */}
        <div className="content-item total">
          <div>
            {contentfulHolidayPageSummary &&
              contentfulHolidayPageSummary[0]?.fields?.total}
          </div>{" "}
          <div className="content-item-price-total">
            € {checkOutDataCart?.shoppingCart?.priceInfo?.total}
          </div>
        </div>

                      {/* <div
              className="content-item-icon"
              onMouseOver={() => handleMouseOver(ShoppingCartTooltipEnums.Downpayment)}
              onMouseOut={() => handleMouseOut(ShoppingCartTooltipEnums.Downpayment)}
            >
              <IconInfo className="icon-inform" />
              {tooltipVisible[ShoppingCartTooltipEnums.Downpayment] && (
                <Tooltip
                  header={
                    contentfulPaymentPage &&
                    contentfulPaymentPage[0].fields.downPaymentTooltipHeader
                  }
                  body={
                    contentfulPaymentPage &&
                    documentToReactComponents(
                      contentfulPaymentPage[0].fields.downPaymentTooltipBody
                    )
                  }
                />
              )}
            </div> */}

        {/* DOWNPAYMENT OPTION */}
        {checkOutDataCart?.paymentInfo?.downPaymentPossible &&
          !checkOutDataCart?.paymentInfo?.downPaymentSelected && (
            <div className="content-item">
              <div>
                {contentfulHolidayPageSummary &&
                  contentfulHolidayPageSummary[0]?.fields?.betalenHeaderFee}
                <div
                  className="content-item-icon"
                  onMouseOver={() =>
                    handleMouseOver(ShoppingCartTooltipEnums.Downpayment)
                  }
                  onMouseOut={() =>
                    handleMouseOut(ShoppingCartTooltipEnums.Downpayment)
                  }
                >
                  <IconInfo className="icon-inform" />
                  {tooltipVisible[ShoppingCartTooltipEnums.Downpayment] && (
                    <Tooltip
                    header={
                      contentfulPaymentPage &&
                      contentfulPaymentPage[0].fields.downPaymentTooltipHeader
                    }
                      body={
                        contentfulPaymentPage &&
                        documentToReactComponents(
                          contentfulPaymentPage[0].fields.downPaymentTooltipBody
                        )
                      }
                    />
                  )}
                </div>
              </div>{" "}
              <div className="content-item-price">
                € {checkOutDataCart?.paymentInfo?.downPaymentAmount}
              </div>
            </div>
          )}

        {checkOutDataCart?.paymentInfo?.paymentSelected && (
          <button class="new-search">
            {contentfulConfirmationPage &&
              contentfulConfirmationPage[0]?.fields?.newSearch}
          </button>
        )}
      </div>
    </Fragment>
  );
};
