import {GetAffiliate} from "../../../services/general.service";
import {affiliates} from "../../../common/enums/affiliates";
import "../../../assets/fonts/Nexa-XBold-Regular.ttf";

export const PriceMarkerComponent = ({ price }) => {
    const affiliate = GetAffiliate();
    const priceLength = price.length;
    const svgWidth = 20 + priceLength * 8;

    const svgStringKLM = `<svg width="${svgWidth}px" height="30px" viewBox="0 0 ${svgWidth} 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>MapPriceIndicator/Hover+Active</title>
    <g id="MapPriceIndicator/Hover+Active" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path d="M2,0 L${svgWidth - 2},0 C${svgWidth - 1.8954305},0 ${svgWidth - 1},0.8954305 ${svgWidth - 1},2 L${svgWidth - 1},22 C${svgWidth - 1},23.1045695 ${svgWidth - 1.1045695},24 ${svgWidth - 2},24 L${svgWidth - 12},24 L${svgWidth - 12},24 L${svgWidth - 18},24 L${svgWidth - 24},30 L18,24 L12,24 L2,24 C0.8954305,24 1.3527075e-16,23.1045695 0,22 L0,2 C-1.3527075e-16,0.8954305 0.8954305,2.02906125e-16 2,0 Z" id="Rectangle" fill='#E98F4F'></path>
      <text text-anchor="middle" id="price" font-family="UniversalSans-Text-Regular, Roboto" font-size="14" font-weight="normal" line-spacing="20" letter-spacing="0.2" fill="#FFFFFF" x="${svgWidth / 2}" y="15">
        <tspan>€ ${price}</tspan>
      </text>
    </g>
  </svg>`;

    const svgStringTransavia =
    `<svg width="${svgWidth}px" height="30px" viewBox="0 0 ${svgWidth} 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>MapPriceIndicator/Hover+Active</title>
      <g id="MapPriceIndicator/Hover+Active" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path d="M2,0 L${svgWidth - 2},0 C${svgWidth - 1.8954305},0 ${svgWidth - 1},0.8954305 ${svgWidth - 1},2 L${svgWidth - 1},22 C${svgWidth - 1},23.1045695 ${svgWidth - 1.1045695},24 ${svgWidth - 2},24 L${svgWidth - 12},24 L${svgWidth - 12},24 L${svgWidth - 18},24 L${svgWidth - 24},30 L18,24 L12,24 L2,24 C0.8954305,24 1.3527075e-16,23.1045695 0,22 L0,2 C-1.3527075e-16,0.8954305 0.8954305,2.02906125e-16 2,0 Z" id="Rectangle" fill='#2800A0'></path>
        <text text-anchor="middle" id="price" font-family="Nexa-XBold-Regular" font-size="14" font-weight="normal" line-spacing="20" letter-spacing="0.2" fill="#FFFFFF" x="${svgWidth / 2}" y="15">
          <tspan>€ ${price}</tspan>
        </text>
      </g>
    </svg>`;

    const svgString = affiliate === affiliates.klm ? svgStringKLM: svgStringTransavia;
    const encodedSvgString = encodeURIComponent(svgString);
    return `data:image/svg+xml;charset=UTF-8,${encodedSvgString}`;
};
