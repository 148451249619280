import React, {
  useContext,
  useEffect,
  useReducer,
  useState
} from "react";
import { useSelector } from "react-redux";
import TransaviaLogo from "../../assets/icons/transavia/transavia-holiday-logo.png";
import { contentType } from "../../common/constants/contentType";
import { affiliates } from "../../common/enums/affiliates";
import { contentfulEntriesReducer } from "../../common/enums/contentfulEntriesReducer";
import { getContentfulByContentType } from "../../services/contentful.service";
import { GetAffiliate } from "../../services/general.service";
import ContentfulContext from "../../store/contentful/contentful-context";
import { AccomodationComponent } from "../accomodation/accomodation.component";
import { AutoComponent } from "../auto/auto.component";
import { BaggageComponent } from "../baggage/baggage-component";
import { FlightComponent } from "../flight/flight.component";
import { HotelLocationComponent } from "../hotel/hotel-location/hotel-location.component";
import { HotelStarRatingComponent } from "../hotel/hotel-star-rating/hotel-star-rating.component";
import { HotelComponent } from "../hotel/hotel.component";
import { PriceOverviewTotal } from "../price-overview-total/price-overview-total.component";
import { PriceOverviewComponent } from "../price-overview/price-overview.component";
import { ShoppingCartInsuranceComponent } from "../shopping-cart-insurance/shopping-cart-insurance.component";
import { Sustainability } from "../sustainability/sustainability";
import "./shopping-cart.component.scss";
import { TransferShoppingCartComponent } from "../transfer/transfer-shopping-cart.component";

const initialState = {
  contentfulButtons: null,
};
const reducer = (state, action) => {
  switch (action.type) {
    case contentfulEntriesReducer.HOLIDAY_PAGE_SUMMARY:
      return { ...state, contentfulHolidayPageSummary: action.payload };
    case contentfulEntriesReducer.CONFIRMATION_PAGE:
      return { ...state, contentfulConfirmationPage: action.payload };
    case contentfulEntriesReducer.PAYMENT_PAGE:
      return { ...state, contentfulPaymentPage: action.payload };
    case contentfulEntriesReducer.HOLIDAY_PAGE:
      return { ...state, contentfulHolidayPage: action.payload };
    case contentfulEntriesReducer.LUGGAGE_SELECTION:
      return { ...state, contentfulLuggageSelection: action.payload };
    default:
      throw new Error("Unknown data");
  }
};

export const ShoppingCartComponent = ({ className, children }) => {
  const contentfulEntries = useContext(ContentfulContext);
  const checkOutDataCart = useSelector((state) => state.checkOut.checkOutData);
  const insuranceInfo =
  checkOutDataCart?.shoppingCart?.insuranceInfo?.insuranceSelected;
  const [isLocalFeeEmpty, setIsLocalFeeEmpty] = useState(true);
  const [insuranceVisible, setInsuranceVisible] = useState(false);

  const [
    {
      contentfulHolidayPageSummary,
      contentfulConfirmationPage,
      contentfulPaymentPage,
      contentfulHolidayPage,
      contentfulLuggageSelection,
    },
    dispatch,
  ] = useReducer(reducer, initialState);

  useEffect(() => {
    if (insuranceInfo) {
      setInsuranceVisible(true);
    } else {
      setInsuranceVisible(false);
    }
  }, [insuranceVisible,insuranceInfo]);
  
  useEffect(() => {
    const localFee = checkOutDataCart?.shoppingCart?.priceInfo?.localFee;
    setIsLocalFeeEmpty(!localFee);
  }, [checkOutDataCart]);

  const contentfulHolidayPageResponse = () => {
    const contentfulHolidayPageSummary = getContentfulByContentType(
      contentfulEntries,
      contentType.HOLIDAY_PAGE_SUMMARY
    );

    const contentfulConfirmationPage = getContentfulByContentType(
      contentfulEntries,
      contentType.CONFIRMATION_PAGE
    );

    const contentfulPaymentPage = getContentfulByContentType(
      contentfulEntries,
      contentType.PAYMENT_PAGE
    );

    const contentfulHolidayPage = getContentfulByContentType(
      contentfulEntries,
      contentType.HOLIDAY_PAGE
    );

    const contentfulShoppingCart = getContentfulByContentType(
      contentfulEntries,
      contentType.CONFIRMATION_PAGE
    );

    const contentfulLuggageSelection = getContentfulByContentType(
      contentfulEntries,
      contentType.LUGGAGE_SELECTION
    );

    dispatch({
      type: contentfulEntriesReducer.PAYMENT_PAGE,
      payload: contentfulPaymentPage,
    });

    dispatch({
      type: contentfulEntriesReducer.HOLIDAY_PAGE_SUMMARY,
      payload: contentfulHolidayPageSummary,
    });

    dispatch({
      type: contentfulEntriesReducer.CONFIRMATION_PAGE,
      payload: contentfulConfirmationPage,
    });
    dispatch({
      type: contentfulEntriesReducer.HOLIDAY_PAGE,
      payload: contentfulHolidayPage,
    });
    dispatch({
      type: contentfulEntriesReducer.LUGGAGE_SELECTION,
      payload: contentfulLuggageSelection,
    });
  };

  useEffect(() => {
    contentfulHolidayPageResponse();
  }, [contentfulEntries]);

  return (
    <div className={`shopping-cart-component ${className}`}>
      {children}
      <div className="summary-icon">
        <div className="shopping-cart-box">
          {contentfulHolidayPageSummary &&
            contentfulHolidayPageSummary[0]?.fields?.affiliateLogo?.fields &&
            (GetAffiliate() === affiliates.klm ? (
              <>
                <div className="klm-logo">
                  <img
                    src={
                      contentfulHolidayPageSummary &&
                      contentfulHolidayPageSummary[0]?.fields?.affiliateLogo
                        ?.fields?.file.url
                    }
                    alt="affiliate-logo"
                  />
                </div>
              </>
            ) : GetAffiliate() === affiliates.transavia ? (
              <img src={TransaviaLogo} className="logo" alt="logo" />
            ) : null)}
        </div>
      </div>
      {/* hotel (picture and details) */}
      <div className="shopping-cart-hotel">
        <div className="shopping-cart-box">
          <div className="holiday-card-hotel-name">
            {checkOutDataCart && checkOutDataCart.shoppingCart ? (
              <HotelComponent
                data={{
                  hotel: {
                    name: checkOutDataCart.shoppingCart.hotelInfo.hotelName,
                  },
                }}
              />
            ) : null}
          </div>
          <div className="checkout-card-hotel-rating">
            {checkOutDataCart && checkOutDataCart.shoppingCart ? (
              <HotelStarRatingComponent
                starRating={checkOutDataCart.shoppingCart.hotelInfo.stars}
              />
            ) : null}
          </div>
          <div className="checkout-card-hotel-location">
            <HotelLocationComponent
              data={checkOutDataCart?.shoppingCart?.hotelInfo}
              showFlag={false}
            />
          </div>
        </div>
      </div>
      {/* accomodation */}
      <div className="section shopping-cart-accomodation">
        <div className="shopping-cart-box">
          {checkOutDataCart && checkOutDataCart.shoppingCart ? (
            <AccomodationComponent
              data={checkOutDataCart.shoppingCart.hotelInfo}
              contentfulHolidayPageSummary={contentfulHolidayPageSummary}
              contentfulHolidayPage={contentfulHolidayPage}
            />
          ) : null}
        </div>
      </div>

      {/* outbound flight */}
      <div className="section shopping-cart-flight">
        <div className="shopping-cart-box">
          <div className="shopping-cart-flight-outbound">
            {checkOutDataCart && checkOutDataCart.shoppingCart ? (
              <FlightComponent
                data={checkOutDataCart.shoppingCart.flightInfo.outBound}
                showFlightIcon={true}
                showTitle={true}
                contentfulHolidayPageSummary={contentfulHolidayPageSummary}
              />
            ) : null}
          </div>
          {/* inbound flight */}
          {checkOutDataCart && checkOutDataCart.shoppingCart ? (
            <FlightComponent
              data={checkOutDataCart.shoppingCart.flightInfo.inBound}
              showFlightIcon={false}
              showTitle={false}
              contentfulHolidayPageSummary={contentfulHolidayPageSummary}
            />
          ) : null}
        </div>
      </div>

      {/* baggage */}
      <div className="section shopping-cart-baggage">
        <div className="shopping-cart-box">
          <BaggageComponent
            contentfulHolidayPageSummary={contentfulHolidayPageSummary}
            contentfulLuggageSelection={contentfulLuggageSelection}
          />
        </div>
      </div>

      {/* auto */}
      {checkOutDataCart?.shoppingCart?.rentalCarInfo?.carSelected && (
        <div className="section shopping-cart-baggage">
          <div className="shopping-cart-box">
            <AutoComponent
              contentfulHolidayPageSummary={contentfulHolidayPageSummary}
            />
          </div>
        </div>
      )}

      {/* transfer */}
        {checkOutDataCart?.shoppingCart?.transfersInfo?.transferSelected && (
        <div className="section shopping-cart-baggage">
          <div className="shopping-cart-box">
            <TransferShoppingCartComponent
              contentfulHolidayPageSummary={contentfulHolidayPageSummary}
            />
          </div>
        </div>
      )}

      {/* insurance */}
      {insuranceVisible && (
        <>
          <div className="section shopping-cart-insurance">
            <div className="shopping-cart-box">
              <ShoppingCartInsuranceComponent
                insurance={checkOutDataCart?.shoppingCart?.insuranceInfo}
                contentfulHolidayPageSummary={contentfulHolidayPageSummary}
              />
            </div>
          </div>
        </>
      )}

      {/* co2compensation */}
      {checkOutDataCart &&
      checkOutDataCart?.shoppingCart?.co2CompensationInfo
        ?.co2CompensationSelected ? (
        GetAffiliate() === affiliates.klm ? (
          <div className="sustainability-component">
            <div className="shopping-cart-box">
              <Sustainability
                contentfulHolidayPageSummary={contentfulHolidayPageSummary}
                contentfulHolidayPage={contentfulHolidayPage}
                data={checkOutDataCart?.shoppingCart?.co2CompensationInfo}
              />
            </div>
          </div>
        ) : GetAffiliate() === affiliates.transavia ? null : null
      ) : null}

      {/* price overview */}
      <div className="section shopping-cart-price-overview">
        <div className={`shopping-cart-box ${isLocalFeeEmpty ? "close" : ""}`}>
          {checkOutDataCart && checkOutDataCart.shoppingCart ? (
            <PriceOverviewComponent
              data={checkOutDataCart?.shoppingCart?.priceInfo}
              insurance={
                checkOutDataCart?.additionalProductInfo?.insuranceInfo
                  ?.insuranceSelectionCompleted &&
                !checkOutDataCart?.additionalProductInfo?.insuranceInfo
                  ?.noInsuranceSelected
                  ? checkOutDataCart?.shoppingCart?.insuranceInfo
                  : false
              }
              contentfulHolidayPageSummary={contentfulHolidayPageSummary}
            />
          ) : null}
        </div>
      </div>
      <div className="price-ovierview-total">
        <div className="shopping-cart-box">
          {checkOutDataCart && checkOutDataCart.shoppingCart ? (
            <PriceOverviewTotal
              contentfulHolidayPageSummary={contentfulHolidayPageSummary}
              contentfulConfirmationPage={contentfulConfirmationPage}
              contentfulPaymentPage={contentfulPaymentPage}
            />
          ) : null}
        </div>
      </div>
      {children}
    </div>
  );
};
