const klmClusterImg = 'data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"%3E%3Cg id="KLM" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"%3E%3Cg id="KLM/MapNumberOfHotelsIndicator/Round99+"%3E%3Ccircle id="Oval" fill="%23E37222" cx="24" cy="24" r="24" /%3E%3Ctext id="9999" font-family="KLMNoa-Regular, KLM Noa" font-size="16" font-weight="normal" line-spacing="24" letter-spacing="0.46" fill="%23FFFFFF"%3E%3Ctspan x="3.88" y="21"%3E%3C/tspan%3E%3C/text%3E%3C/g%3E%3C/g%3E%3C/svg%3E';
const trasnaviaClusterImg = "data:image/svg+xml;charset=UTF-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40px' height='40px' viewBox='0 0 40 40' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Transavia' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Transavia_MapIndicator_Round_Medium_Hover'%3E%3Ccircle id='Oval' fill='%235E40B8' cx='20' cy='20' r='20' /%3E%3Ctext id='35' font-family='Nexa-Book, Nexa Book' font-size='16' font-weight='normal' line-spacing='24' letter-spacing='0.46' fill='%23FFFFFF'%3E%3Ctspan x='8.9696875' y='24'%3E%20%3C/tspan%3E%3C/text%3E%3C/g%3E%3C/g%3E%3C/svg%3E"

export const clusterStylesForKLM = {
    default: [{
      height: 32,
      textColor: "#FFFFFF",
      width: 32,
      textSize: 16,
      fontFamily: "UniversalSans-Display-Light",
      url: klmClusterImg,
    },
    {
      height: 48,
      textColor: "#FFFFFF",
      width: 48,
      textSize: 16,
      fontFamily: "UniversalSans-Display-Light",
      url: klmClusterImg,
    },
    {
      height: 64,
      textColor: "#FFFFFF",
      width: 64,
      textSize: 16,
      fontFamily: "UniversalSans-Display-Light",
      url: klmClusterImg,
    }],
};


  export const clusterStylesForTransavia = {
    default: [{
      height: 32,
      textColor: "#FFFFFF",
      width: 32,
      textSize: 16,
      fontFamily: "Nexa-Book-Regular",
      url: trasnaviaClusterImg,
    },
    {
      height: 48,
      textColor: "#FFFFFF",
      width: 48,
      textSize: 16,
      fontFamily: "Nexa-Book-Regular",
      url: trasnaviaClusterImg,
    },
    {
      height: 64,
      textColor: "#FFFFFF",
      width: 64,
      textSize: 16,
      fontFamily: "Nexa-Book-Regular",
      url: trasnaviaClusterImg,
    }]
};