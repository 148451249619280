import { GetAffiliate } from "../../services/general.service";
import { ReactComponent as FlightIcon } from "../../assets/icons/klm/icon-flight.svg";
import { ReactComponent as FlightIconTransavia } from "../../assets/icons/transavia/icon-flight-transavia.svg";
import {affiliates} from "../../common/enums/affiliates";

export const FlightComponent = ({
  data,
  showFlightIcon,
  showTitle,
  contentfulHolidayPageSummary,
}) => {
  const {
    departureAirportCode,
    arrivalAirportCode,
    directFlight,
    flightDateTime,
    cabinClass,
    daysLaterArrivalDescription
  } = data;

  const renderIcon = () => {
    const affiliate = GetAffiliate();

    if (showFlightIcon) {
      if (affiliate === affiliates.klm) {
        return <FlightIcon />;
      } else if (affiliate === affiliates.transavia) {
        return <FlightIconTransavia />;
      }
    }
    return null;
  };

  return (
    <div className="flight-component">
      <div className="flight-component-header">
        {renderIcon()}
        {showTitle
          ? contentfulHolidayPageSummary &&
            contentfulHolidayPageSummary[0]?.fields?.flights
          : ""}
      </div>
      <div className="section-header">
        {departureAirportCode} - {arrivalAirportCode}{" "}
        {directFlight ?
            (contentfulHolidayPageSummary &&
          contentfulHolidayPageSummary[0]?.fields?.directFlight)
        : ((contentfulHolidayPageSummary &&
                contentfulHolidayPageSummary[0]?.fields?.indirectFlight))
        }
      </div>
      <div>{flightDateTime} <span className="days-later-arrival">{daysLaterArrivalDescription}</span></div>
      {GetAffiliate() === affiliates.klm ? (
        <div className="flight-cabin-class">{cabinClass + " class"}</div>
      ) : (
        ""
      )}
    </div>
  );
};
