import { FlagComponent } from "../../../stories/components/flag/flag.component";

export const HotelLocationComponent = ({ data, showFlag }) => {
  if (!data) return null;

  const isHotelDataPresent = data.hotel && data.hotel.location;

  let country, city, countryDescription;

  if (isHotelDataPresent) {
    country = data.hotel.location.countryCode;
    city = data.hotel.location.city;
    countryDescription = data.hotel.location.countryDescription;
  } else {
    country = data.countryCode;
    city = data.location;
    countryDescription = data.countryDescription;
  }

  return (
    <div className="hotel-location-component">
      {showFlag && country && (
        <FlagComponent country={country} size={"small"} />
      )}
      <span>
        {countryDescription ? countryDescription + " - " : ""}
        {city}
      </span>
    </div>
  );
};
