import styled from "styled-components";
import "./map.component.scss";
import { GenericButton } from "../generic-button/generic-button";

const ExpandMapButton = styled.div`
  position: absolute;
  display: flex;
  margin: auto;
  transform: translate(calc(25% - 20px), calc(50% + 20px));
  align-items: center;
`;

export const MapComponent = ({ contentfulButtons }) => {
  return (
    <div className="side-filter-bar-map">
      <ExpandMapButton>
        <GenericButton className="button button-show-map">
          <i className="map-icon"></i>
          <span>
            {contentfulButtons?.length > 0 &&
              contentfulButtons[0]?.fields?.showHotelsOnMap}
          </span>
        </GenericButton>
      </ExpandMapButton>
    </div>
  );
};
