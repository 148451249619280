import { useSelector } from "react-redux";
import { ReactComponent as TransferIcon } from "../../assets/icons/klm/klm_transfer_icon.svg";
import { ReactComponent as TransferIconHV } from "../../assets/icons/transavia/transavia_transfer_icon.svg";
import { GetAffiliate } from "../../services/general.service";
import { affiliates } from "../../common/enums/affiliates";

export const TransferShoppingCartComponent = ({
  contentfulHolidayPageSummary,
}) => {
  const checkOutDataCart = useSelector((state) => state.checkOut.checkOutData);

  return (
    <div className="transfer-shopping-cart-component">
      <div className="section-header">
        {GetAffiliate() === affiliates.klm ? (
          <TransferIcon />
        ) : (
          <TransferIconHV />
        )}
        {contentfulHolidayPageSummary &&
          contentfulHolidayPageSummary[0]?.fields?.holidayPageSummaryExtension
            ?.transfers}
      </div>
      <div className="small-space">
        {checkOutDataCart && contentfulHolidayPageSummary && (
          <>
            <span>
              {checkOutDataCart?.shoppingCart?.transfersInfo?.description}
            </span>
          </>
        )}
      </div>
      <div className="small-space">
        {checkOutDataCart && contentfulHolidayPageSummary && (
          <span>
              {checkOutDataCart?.shoppingCart?.transfersInfo?.bookingClass}

          </span>
        )}
      </div>
    </div>
  );
};
