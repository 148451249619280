import { GetAffiliate } from "../../services/general.service";
import { useSelector } from "react-redux";
import { ReactComponent as IconLugage } from "../../assets/icons/klm/icon-luggage.svg";
import { ReactComponent as IconLugageTransavia } from "../../assets/icons/transavia/icon-luggage-transavia.svg";
import { affiliates } from "../../common/enums/affiliates";
import { useEffect, useState } from "react";

export const BaggageComponent = ({
  contentfulHolidayPageSummary,
  contentfulLuggageSelection,
}) => {
  const checkOutDataCart = useSelector((state) => state.checkOut.checkOutData);
  const [showCabinBaggage, setShowCabinBaggage] = useState(false);

  useEffect(() => {
    setShowCabinBaggage(
      checkOutDataCart?.shoppingCart?.baggageInfo?.showCabinLuggageLabel
    );
  }, [checkOutDataCart]);

  return (
    <div className="baggage-component">
      <div className="section-header">
        {GetAffiliate() === affiliates.klm ? (
          <IconLugage />
        ) : GetAffiliate() === affiliates.transavia ? (
          <IconLugageTransavia />
        ) : null}
        {contentfulHolidayPageSummary &&
          contentfulHolidayPageSummary[0]?.fields?.bagage}
      </div>
      <div className="small-space">
        {checkOutDataCart && contentfulLuggageSelection && (
          <span>{`${checkOutDataCart?.shoppingCart?.baggageInfo?.numberOfHandLuggagePieces}x ${contentfulLuggageSelection[0]?.fields?.shoppingCartHandBaggageLabel}`}</span>
        )}
      </div>
      {showCabinBaggage && (
        <div className="small-space">
          {checkOutDataCart && contentfulLuggageSelection && (
            <span>{`${checkOutDataCart?.shoppingCart?.baggageInfo?.numberOfCabinBaggagePieces}x ${contentfulLuggageSelection[0]?.fields?.shoppingCartCabinLuggageLabel}`}</span>
          )}
        </div>
      )}
      <div>
        {checkOutDataCart && contentfulLuggageSelection && (
          <span>{`${checkOutDataCart?.shoppingCart?.baggageInfo?.numberOfCheckedBaggagePieces}x ${contentfulLuggageSelection[0]?.fields?.shoppingCartCheckedBaggageLabel}`}</span>
        )}
      </div>
    </div>
  );
};
