import React from "react";
import PropTypes from "prop-types";
import { paymentTypes } from "../../common/enums/paymentTypes";
import "./checkout-card.scss";

const CheckoutCard = ({
  type,
  data,
  title,
  travellersCount,
  contentfulPaymentPage,
}) => {
  if (type === paymentTypes.TRAVELLER) {
    return (
      <div
        className={`traveller-container ${data.length > 2 ? "full-width" : ""}`}
      >
        <div className="title-container">{title}</div>
        <div className="traveller-info-container">
          {data.map((traveller, index) => (
            <div
              key={traveller.travellerIdentifier}
              className={`traveller ${index === 1 ? "second-traveller" : ""} ${
                data.length > 2 ? "horizontal" : ""
              }`}
            >
              <div className="traveller-number">
                {contentfulPaymentPage &&
                  contentfulPaymentPage[0]?.fields?.traveller}{" "}
                {traveller.travellerNumber}
              </div>
              <div className="name">
                {traveller.gender === 0
                  ? `${
                      contentfulPaymentPage &&
                      contentfulPaymentPage[0]?.fields?.mr
                    } ${String.fromCharCode(160)}`
                  : `${
                      contentfulPaymentPage &&
                      contentfulPaymentPage[0]?.fields?.ms
                    } ${String.fromCharCode(160)}`}
                {`${traveller.firstName || ""}${String.fromCharCode(160)}${
                  traveller.lastName || ""
                }`.trim()}
              </div>
              <div className="dob">{traveller.dateOfBirthDisplay}</div>
              <div className="passenger-type">
                {traveller.travellerType === "Adult"
                  ? contentfulPaymentPage &&
                    contentfulPaymentPage[0]?.fields?.adult
                  : contentfulPaymentPage &&
                    contentfulPaymentPage[0]?.fields?.child}
                {traveller.isMainBooker ? "," : ""}
                {' '}
                <span>
                  {traveller.isMainBooker
                    ? contentfulPaymentPage &&
                      contentfulPaymentPage[0]?.fields?.mainBooker
                    : ""}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (type === paymentTypes.MAINBOOKER) {
    const mainBookerTraveller = data?.travellerInfo?.travellers?.find(
      (traveller) => traveller.isMainBooker
    );
    const mainBookerContact = data?.contactDetailInfo?.mainBooker;
    return (
      <div
        className={`mainbooker ${
          travellersCount === 2 || travellersCount === 1 ? "mainbooker-inline" : "moved-down"
        }`}
      >
        <div className="title-container">{title}</div>
        <div className="mainbooker-container">
          <div className="data-mainbooker-info">
            <p className="title-item">
              {contentfulPaymentPage && contentfulPaymentPage[0]?.fields?.adress}
            </p>
            <div className="name">
              {mainBookerTraveller?.gender === 0
                ? contentfulPaymentPage && contentfulPaymentPage[0]?.fields?.mr
                : contentfulPaymentPage &&
                  contentfulPaymentPage[0]?.fields?.ms}{" "}
              {`${mainBookerTraveller?.firstName || ""} ${
                mainBookerTraveller?.lastName || ""
              }`.trim()}
            </div>
            <div className="address">
              {mainBookerContact?.address.street}{" "}
              {mainBookerContact?.address.houseNumber}
              {mainBookerContact?.address.houseNumberExtension
                ? `-${mainBookerContact?.address.houseNumberExtension}`
                : ""}
            </div>
            <div className="zip-code">
              <div>
                {mainBookerContact?.address.zipCode}{" "}
                {mainBookerContact?.address.city}
              </div>
            </div>
          </div>
          <div className="data-info-container">
            <div className="mobile-info">
              <p className="title-item">
                {contentfulPaymentPage &&
                  contentfulPaymentPage[0]?.fields?.mobileNumber}
              </p>
              <div className="phone">
                {mainBookerContact?.phoneNumberCountryCode}{" "}
                {mainBookerContact?.phoneNumberSuffix}
              </div>
            </div>
            <div className="email-info">
              <p className="title-item">
                {contentfulPaymentPage && contentfulPaymentPage[0]?.fields?.mail}
              </p>
              <div className="email">{mainBookerContact?.email}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (type === paymentTypes.HOMESTAYER) {
    return (
      <div
        className={`homestayer ${
          data.length === 2 ||  data.length === 1 ? "block-display" : "inline"
        }`}
      >
        <div className="title-container">{title}</div>
        <div className="name">
          {`${data.firstName || ""} ${data.lastName || ""}`.trim()}
        </div>
        <div className="phone">
          {data.phoneNumberCountryCode} {data.phoneNumberSuffix}
        </div>{" "}
      </div>
    );
  }

  return null;
};

CheckoutCard.propTypes = {
  type: PropTypes.oneOf([
    paymentTypes.TRAVELLER,
    paymentTypes.MAINBOOKER,
    paymentTypes.HOMESTAYER,
  ]).isRequired,
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]).isRequired,
  title: PropTypes.string,
  travellersCount: PropTypes.number,
};

export default CheckoutCard;
