import {StarRatingComponent} from "../../star-rating/star-rating.component";
import {FaTripadvisor} from "react-icons/fa";
import {TripAdvisorRatingComponent} from "../../trip-advisor-rating/trip-advisor-rating.component";
import '../hotel-list-map.component.scss'
import React, {useContext, useEffect, useState} from "react";
import {getContentfulByContentType} from "../../../services/contentful.service";
import {contentType} from "../../../common/constants/contentType";
import ContentfulContext from "../../../store/contentful/contentful-context";

const InfoWindowContentComponent = ({activeMarker, contentfulButtons}) => {
    const [contentfulHolidaysComponent, setContentfulHolidaysComponent] = useState('')
    const contentfulEntries = useContext(ContentfulContext);
    useEffect(() => {
        const contentfulHolidaysComponentResponse = getContentfulByContentType(
            contentfulEntries,
            contentType.HOLIDAYS_COMPONENT
        );
        setContentfulHolidaysComponent(contentfulHolidaysComponentResponse);
    },[activeMarker, contentfulButtons]);

    const navigateToHolidayPage = () => {
        window.location.href = `${activeMarker.holidayPageLink}`
    }
        return (
        <div className="info-window-content-component">
            <div>
                <div className="hotel-name-info">
                    {activeMarker?.hotelName}
                </div>
                <div className="ratings-container">
                    <div className="star-rating-container">
                        <StarRatingComponent data={parseInt(activeMarker?.rating?.starRating)}/>
                    </div>
                    <span className="vertical-spacer"></span>
                    <div className="trip-advisor-rating-container">
                        <FaTripadvisor fontSize="1.25rem"/>
                        <TripAdvisorRatingComponent rating={activeMarker?.rating.tripAdvisorRating}/>
                    </div>
                </div>
            </div>

            <div className="price-marker-container">
                <div className="holiday-price-wrapper">
                    <div className="holiday-card-price-block">
                        <span className="info-price-description">{contentfulHolidaysComponent[0]?.fields?.priceFromText}</span>
                        <span className="info-price-text">
                          € {activeMarker?.pricePerPerson}
                        </span>
                        <span className="info-price-description">{contentfulHolidaysComponent[0]?.fields?.perPersonText}</span>
                    </div>
                </div>
                <div className="button-wrapper">
                    <button onTouchStart={navigateToHolidayPage} onClick={navigateToHolidayPage} className="button button-accept">
                        {contentfulButtons[0]?.fields?.lookAt}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default InfoWindowContentComponent;
