import React, { useState, useEffect } from "react";
import "./downpayment.component.scss";
import { useWindowSizeDetector } from "../../hooks/useWindowSizeDetector";
import { breakpoints } from "../../common/constants/breakpoints";
import { parseContentfulDynamicContent } from "../../services/contentful.service";
import axios from "axios";
import { GetApiUrl } from "../../services/general.service";
import { useDispatch } from "react-redux";
import { ReactComponent as IconInfo } from "../../assets/icons/klm/icon-information.svg";
import { updateCheckOutDataReducer } from "../../store/shopping/slice";
import Tooltip from "../tooltip/tooltip";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { ShoppingCartTooltipEnums } from "../../common/enums/shoppingCartTooltipEnums";

export const DownPaymentComponent = ({
  contentfulPaymentPage,
  checkoutData,
}) => {
  const windowSizeDetector = useWindowSizeDetector();
  const dispatch = useDispatch();
  const [downpaymentSelected, setDownpaymentSelected] = useState(false);
  const [isDownpaymentSelected, setIsDownpaymentSelected] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState({});

  useEffect(() => {
    if (windowSizeDetector.width < breakpoints.SM) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowSizeDetector]);

  const handleMouseOver = (key) => {
    setTooltipVisible((prevState) => ({ ...prevState, [key]: true }));
  };

  const handleMouseOut = (key) => {
    setTooltipVisible((prevState) => ({ ...prevState, [key]: false }));
  };

  const handleOnPaymentOptionSelection = async (value) => {
    setIsDownpaymentSelected(value);
    if (value !== isDownpaymentSelected) {
      if (value) {
        const response = await axios.put(
          `${GetApiUrl()}/CheckOut/${checkoutData.checkOutKey}/DownPayment`
        );
        dispatch(updateCheckOutDataReducer(response.data));
      } else {
        const response = await axios.delete(
          `${GetApiUrl()}/CheckOut/${checkoutData.checkOutKey}/DownPayment`
        );
        dispatch(updateCheckOutDataReducer(response.data));
      }

      setDownpaymentSelected(value);
    }
  };

  return (
    checkoutData?.paymentInfo?.downPaymentPossible && (
      <div className="downpayment-component">
        <div className="downpayment-header">
          {contentfulPaymentPage &&
            contentfulPaymentPage[0].fields.downpayment.header}
        </div>
        <div className="downpayment-container">
          <div className="downpayment-body">
            {contentfulPaymentPage &&
              contentfulPaymentPage[0].fields.downpayment.body}

            <div
              className="content-item-icon"
              onMouseOver={() =>
                handleMouseOver(ShoppingCartTooltipEnums.Downpayment)
              }
              onMouseOut={() =>
                handleMouseOut(ShoppingCartTooltipEnums.Downpayment)
              }
            >
              <IconInfo className="icon-inform" />
              {tooltipVisible[ShoppingCartTooltipEnums.Downpayment] && (
                <Tooltip
                  header={
                    contentfulPaymentPage &&
                    contentfulPaymentPage[0].fields.downPaymentTooltipHeader
                  }
                  body={
                    contentfulPaymentPage &&
                    documentToReactComponents(
                      contentfulPaymentPage[0].fields.downPaymentTooltipBody
                    )
                  }
                />
              )}
            </div>
          </div>

          <div
            className="downpayment-option"
            onClick={() => handleOnPaymentOptionSelection(false)}
          >
            <div className="checkbox-wrapper">
              <div
                className={
                  !downpaymentSelected
                    ? "checkbox-container active"
                    : "checkbox-container inactive"
                }
              >
                <div className="checkbox"></div>
              </div>
            </div>

            {contentfulPaymentPage &&
              parseContentfulDynamicContent(
                contentfulPaymentPage[0].fields.downpayment
                  .downpaymentNotSelectedOption
              )}

            {` €${"\u00A0"}${checkoutData?.paymentInfo?.paymentAmount}.`}
          </div>
          <div
            className="downpayment-option"
            onClick={() => handleOnPaymentOptionSelection(true)}
          >
            <div className="checkbox-wrapper">
              <div
                className={
                  downpaymentSelected
                    ? "checkbox-container active"
                    : "checkbox-container inactive"
                }
              >
                <div className="checkbox"></div>
              </div>
            </div>
            <div className="downpayment-selected">
              <div>
                {contentfulPaymentPage &&
                  contentfulPaymentPage[0].fields.downpayment
                    .downpaymentSelectedOptionFirstPart}
              </div>
              {isMobile ? <br /> : null}
              <div className="downpayment-price">{`€ ${checkoutData?.paymentInfo.downPaymentAmount}`}</div>
              <div>
                {contentfulPaymentPage &&
                  parseContentfulDynamicContent(
                    contentfulPaymentPage[0].fields.downpayment
                      .downpaymentSelectedOptionSecondPart,
                    `€ ${checkoutData?.paymentInfo?.futurePaymentAmount}`,
                    checkoutData?.paymentInfo?.downPaymentLastPaymentDate
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};
