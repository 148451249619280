import "./car.component.scss";
import { CarItemComponent } from "../../../components/car-item/car-item.component";
import { GenericButton } from "../../../components/generic-button/generic-button";
import { useContext, useEffect, useState } from "react";
import { Alert } from "@mui/material";
import { CarFeatureDescription } from "./car-feature-description/car-feature-description.component";
import ContentfulContext from "../../../store/contentful/contentful-context";
import { getContentfulByContentType } from "../../../services/contentful.service";
import { contentType } from "../../../common/constants/contentType";
import { useDispatch as useDispatchShoppingCart } from "react-redux";
import axios from "axios";
import { GetApiUrl } from "../../../services/general.service";
import { updateCheckOutDataReducer } from "../../../store/shopping/slice";

export const CarComponent = ({
  contentfulExtrasPage,
  rentalCarInfo,
  showCustomModal,
  handleViewCarConditions,
  checkOutData,
  setCheckOutData,
  isTransferSelected,
}) => {
  const [expandCarList, setExpandCarList] = useState(false);
  const [contentfulCarComponent, setContentfulCarComponent] = useState();
  const [isWaitingForNetwork, setIsWaitingForNetwork] = useState();
  const contentfulEntries = useContext(ContentfulContext);
  const dispatchData = useDispatchShoppingCart();

  const getCarComponentContent = () => {
    const contentfulResultsPageResponse = getContentfulByContentType(
      contentfulEntries,
      contentType.CAR_COMPONENT
    );
    setContentfulCarComponent(contentfulResultsPageResponse);
  };

  useEffect(() => {
    getCarComponentContent();
  }, [contentfulEntries]);

  const selectCar = async (optionKey) => {
    setIsWaitingForNetwork(true);
    const newCheckoutData = await axios.put(
      `${GetApiUrl()}/CheckOut/${checkOutData?.checkOutKey}/SelectRentalCar`,
      {
        optionKey: optionKey,
      }
    );
    dispatchData(updateCheckOutDataReducer(newCheckoutData.data));
    setCheckOutData(newCheckoutData.data);
    dispatchData(updateCheckOutDataReducer(newCheckoutData.data));
    setIsWaitingForNetwork(false);
  };

  const deleteCar = async (optionKey) => {
    setIsWaitingForNetwork(true);
    const newCheckoutData = await axios.delete(
      `${GetApiUrl()}/CheckOut/${
        checkOutData?.checkOutKey
      }/DeleteRentalCar/${optionKey}`
    );
    dispatchData(updateCheckOutDataReducer(newCheckoutData.data));
    setCheckOutData(newCheckoutData.data);
    dispatchData(updateCheckOutDataReducer(newCheckoutData.data));
    setIsWaitingForNetwork(false);
  };

  const defaultNumberOfCars = 3;

  const handleOnExpandButtonClick = () => {
    setExpandCarList(!expandCarList);
  };
  return (
    <div className="car-component">
      {rentalCarInfo && (
        <>
          <h1 className="section-heading-text">
            {contentfulExtrasPage &&
              contentfulExtrasPage[0]?.fields?.carSectionTitle}
          </h1>

          {isTransferSelected && (
            <div className="option-no-available">
              <Alert className="car-alert" severity="info">
                {contentfulExtrasPage &&
                  contentfulCarComponent[0]?.fields?.carNoSelectionAlert}
              </Alert>
            </div>
          )}
          <div className={`${isTransferSelected ? "opacity-50" : ""}`}>
            <CarFeatureDescription
              contentfulCarComponent={contentfulCarComponent}
            />

            {rentalCarInfo?.rentalCarOptions.map((option, index) =>
              expandCarList ? (
                <CarItemComponent
                  key={index}
                  rentalCarInfo={option}
                  contentfulCarComponent={contentfulCarComponent}
                  contentfulExtrasPage={contentfulExtrasPage}
                  selectCar={selectCar}
                  deleteCar={deleteCar}
                  showCustomModal={showCustomModal}
                  isWaitingForNetwork={isWaitingForNetwork}
                  handleViewCarConditions={handleViewCarConditions}
                />
              ) : (
                index < defaultNumberOfCars && (
                  <CarItemComponent
                    key={index}
                    rentalCarInfo={option}
                    contentfulCarComponent={contentfulCarComponent}
                    contentfulExtrasPage={contentfulExtrasPage}
                    selectCar={selectCar}
                    deleteCar={deleteCar}
                    showCustomModal={showCustomModal}
                    isWaitingForNetwork={isWaitingForNetwork}
                    handleViewCarConditions={handleViewCarConditions}
                  />
                )
              )
            )}

            {rentalCarInfo.rentalCarOptions.length > defaultNumberOfCars && (
              <div className="expand-more-button-wrapper">
                <GenericButton className="expand-more-cars">
                  {expandCarList && (
                    <div
                      className="button-container"
                      onClick={handleOnExpandButtonClick}
                    >
                      <div>
                        {contentfulCarComponent &&
                          contentfulCarComponent[0]?.fields?.carViewLessButton}
                      </div>
                      <div className="dropdown-caret rotate"></div>
                    </div>
                  )}
                  {!expandCarList && (
                    <div
                      className="button-container"
                      onClick={handleOnExpandButtonClick}
                    >
                      {contentfulCarComponent &&
                        contentfulCarComponent[0]?.fields?.carViewMoreButton}
                      <div className="dropdown-caret"></div>
                    </div>
                  )}
                </GenericButton>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
